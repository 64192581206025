import React, { useEffect, useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clickedCurrentStore,
  getLuggageByProvider,
  setClickedStore,
} from "../state/luggage/luggageSlice";
import { getOrderListByStore } from "../state/order/orderSlice";
// const logo = require("../img/logo.png");
import logo from "../img/logo.png";

const DashboardIcon = require("../img/dashboard.png");

const editIcon = require("../img/Edit.png");
const luggageIcon = require("../img/luggage.png");
const reviewIcon = require("../img/review.png");
const storeIcon = require("../img/store.png");
const cardIcon = require("../img/payment.png");

const Sidebar = () => {
  const dispatch = useDispatch();
  const { providerStoreList, currentStore } = useSelector(
    (state) => state.luggageData
  );

  const {selectedStore}=useSelector((state)=>state.luggageData)
  const defaultStore = providerStoreList[0]?.id || 0;
  const [storeId] = useState(currentStore || defaultStore);
  const path = window.location.pathname;
  const { token, loginUser } = useSelector((state) => state.userData);
  useEffect(() => {
    dispatch(setClickedStore(selectedStore || providerStoreList[0]));
  }, [providerStoreList, selectedStore, dispatch]);
  useEffect(() => {
    dispatch(clickedCurrentStore(storeId || defaultStore));
    dispatch(getOrderListByStore({ storeId: currentStore, token }));
    dispatch(getLuggageByProvider({ token, providerId: loginUser.id }));
  }, [storeId, dispatch, currentStore, token, loginUser.id, defaultStore]);

  return (
    <ProSidebar
      style={{ width: "100%", minWidth: "100%", backgroundColor: "#FFFFFF" }}
    >
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: "160px",
            height: "70px",
            marginLeft: "6.9%",
            marginTop: "10px",
          }}
          src={logo}
          alt="logo"
        />
      </div>

      <Menu className=" mt-[65px] pr-1 pt-1 ">
        <MenuItem
          style={{
            backgroundColor: path === "/" ? "#EBEEF2" : "white",
            width: "90%",
            marginLeft: "5%",
            borderRadius: "20px",
            marginTop: "5px",

          }}
          component={<Link to="/" />}
        >
          <div className="menu">
            <div style={{ marginRight: "10px", marginTop: "5px" }}>
              <img src={DashboardIcon} alt="dashboardIcon" />
            </div>
            <div style={{fontFamily:"Poppins"}}>Dashboard</div>
          </div>
        </MenuItem>

        <MenuItem
          style={{
            backgroundColor: path === "/order/List" ? "#EBEEF2" : "",
            width: "90%",
            marginLeft: "5%",
            borderRadius: "20px",
            marginTop: "5px",
          }}
          component={<Link to="/order/List" />}
        >
          <div className="menu">
            <div style={{ marginRight: "10px", marginTop: "5px" }}>
              <img src={luggageIcon} alt="luggaeicon" />
            </div>
            <div style={{fontFamily:"Poppins"}}>Bookings</div>
          </div>
        </MenuItem>
        <MenuItem
          style={{
            backgroundColor: path === "/payment" ? "#EBEEF2" : "",
            width: "90%",
            marginLeft: "5%",
            borderRadius: "20px",
            marginTop: "5px",
          }}
          component={<Link to="/payment" />}
        >
          <div className="menu">
            <div style={{ marginRight: "10px", marginTop: "5px" }}>
              <img src={cardIcon} alt="cardIcon" />
            </div>
            <div style={{fontFamily:"Poppins"}}>Payments</div>
          </div>
        </MenuItem>
        <MenuItem
          style={{
            backgroundColor: path === "/store/review" ? "#EBEEF2" : "",
            width: "90%",
            marginLeft: "5%",
            borderRadius: "20px",
            marginTop: "5px",
          }}
          component={<Link to="/store/review" />}
        >
          <div className="menu">
            <div style={{ marginRight: "10px", marginTop: "5px" }}>
              <img src={reviewIcon} alt="reviewIcon" />
            </div>
            <div style={{fontFamily:"Poppins"}}>Reviews</div>
          </div>
        </MenuItem>
        {selectedStore && (
          <MenuItem
            style={{
              backgroundColor: path === "/store/list" ? "#EBEEF2" : "",
              width: "90%",
              marginLeft: "5%",
              borderRadius: "20px",
              marginTop: "5px",
            }}
            component={<Link to="/store/list" />}
          >
            <div className="menu">
              <div style={{ marginRight: "10px", marginTop: "5px" }}>
                <img src={storeIcon} alt="storeIcon" />
              </div>
              <div style={{fontFamily:"Poppins"}}>Your Store</div>
            </div>
          </MenuItem>
        )}
        <MenuItem
          style={{
            backgroundColor: path === "/create/new/store" ? "#EBEEF2" : "",
            width: "90%",
            marginLeft: "5%",
            borderRadius: "20px",
            marginTop: "5px",
          }}
          component={<Link to="/ask/create/store" />}
        >
          <div className="menu">
            <div style={{ marginRight: "10px", marginTop: "5px" }}>
              <img src={editIcon} alt="editIcon" />
            </div>
            <div style={{fontFamily:"Poppins"}}>Create Store</div>
          </div>
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
