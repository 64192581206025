const frTmeFormat = (time) => {
  const dateTime = new Date(time).toLocaleString("fr-FR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "Europe/Paris",
  });
  return dateTime;
};

export default frTmeFormat;
