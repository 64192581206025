/** @format */

import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import BecomeVendor from "./pages/BecomeVendor";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { PrivateRoutes } from "./utils/privateRoutes";
import CreateStore from "./pages/CreateStore";
import StoreList from "./pages/StoreList";
import StoreDetails from "./pages/StoreDetails";
import Profile from "./pages/Profile";
import VendorSettings from "./pages/VendorSettings";
import Payment from "./pages/PaymentPage";
import Review from "./pages/Review";
import OrderPage from "./pages/OrderPage";
import CreateAnotherStore from "./pages/CreateAnotherStore";
import AskToCreateStore from "./pages/AskToCreateStore";
import ForgetPage from "./pages/ForgetPage";

const App = () => {
  return (
    <Routes>
      {/* <Route path="otp" element={<Otp />} /> */}
      <Route path="signup" element={<Signup />} />
      <Route path="login" element={<Login />} />
      <Route path="forgetpassword" element={<ForgetPage />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="/become/vendor" element={<BecomeVendor />} />
        <Route path="create/store" element={<CreateStore />} />
        <Route path="create/new/store" element={<CreateAnotherStore />} />
        <Route path="store/list" element={<StoreList />} />
        <Route path="store/seetings" element={<StoreDetails />} />
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<VendorSettings />} />
        <Route path="payment" element={<Payment />} />
        <Route path="store/review" element={<Review />} />
        <Route path="order/list" element={<OrderPage />} />
        <Route path="ask/create/store" element={<AskToCreateStore />} />
      </Route>
    </Routes>
  );
};
export default App;
