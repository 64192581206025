/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  Switch,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import ImageIcon from "@mui/icons-material/Image";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { updateStore } from "../state/luggage/luggageSlice";
import { MuiFileInput } from "mui-file-input";
import formatTimeByHourAndMinute from "../utils/formatTime";
import "@fontsource/poppins";
import "@fontsource/poppins";
import imageCompression from "browser-image-compression";
import Layout from "../components/layout";
import { useNavigate } from "react-router-dom";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
var countries = require("countries-cities").getCountries();
var cityList = require("countries-cities");
// ===>>

const StoreList = () => {
  const { currentStore, selectedStore, error, isLoading } = useSelector(
    (state) => state.luggageData
  );


  const navigate = useNavigate();
  const userReducer = useSelector((state) => state.userData);
  const provider = userReducer.loginUser;
  const { token } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [storeName, setStoreName] = useState(selectedStore?.businessName);
  console.log("select Store ---->", selectedStore);
 
  console.log("sotre Name-->", storeName);
  const [providerName, setProviderName] = useState(selectedStore?.providerName);
  const [providerEmail, setProviderEmail] = useState(
    selectedStore?.providerName || ""
  );
  const [providerMobile, setProviderMobile] = useState(
    selectedStore?.providerMobile || ""
  );
  const [providerID, setProviderID] = useState(selectedStore?.providerID);
  const [cityName, setCityName] = useState(selectedStore?.cityName);
  const [countryName, setCountryName] = useState(
    selectedStore?.countryName || "s"
  );
  const cityArr = cityList.getCities(countryName);

  const [mapLat, setMapLat] = useState(selectedStore?.mapLat || null);
  const [mapLan, setMapLan] = useState(selectedStore?.mapLan || null);
  const [zipCode, setZipCode] = useState(selectedStore?.zipCode || null);
  const [bussinessType, setBussinessType] = useState(
    selectedStore?.bussinessType || ""
  );
  const [sundayOpeningTime, setSundayOpeningTime] = useState(
    selectedStore?.sundayStrtTime || null
  );
  const [sundayClosingTime, setSundayClosingTime] = useState(
    selectedStore?.sundayCloseTime || null
  );
  const [mondayOpeningTime, setMondayOpeningTime] = useState(
    selectedStore?.mondayStrtTime || null
  );

  const [mondayClosingTime, setMondayClosingTime] = useState(
    selectedStore?.mondayCloseTime || null
  );
  const [tuesdayOpeningTime, setTuesdayOpeningTime] = useState(
    selectedStore?.tuesdayStrtTime || null
  );
  const [tuesdayClosingTime, setTuesdayClosingTime] = useState(
    selectedStore?.tuesdayCloseTime || null
  );
  const [wednesdayOpeningTime, setWednesdayOpeningTime] = useState(
    selectedStore?.wednesdayStrtTime || null
  );
  const [wednesdayClosingTime, setWednesdayClosingTime] = useState(
    selectedStore?.wednesdayCloseTime || null
  );
  const [thursdayOpeningTime, setThursdayOpeningTime] = useState(
    selectedStore?.thursdayStrtTime || null
  );
  const [thursdayClosingTime, setThursdayClosingTime] = useState(
    selectedStore?.thursdayCloseTime || null
  );
  const [fridayOpeningTime, setFridayOpeningTime] = useState(
    selectedStore?.fridayStrtTime || null
  );
  const [fridayClosingTime, setFridayClosingTime] = useState(
    selectedStore?.fridayCloseTime || null
  );
  const [saturdayOpeningTime, setSaturdayOpeningTime] = useState(
    selectedStore?.saturdayStrtTime || null
  );
  const [saturdayClosingTime, setSaturdayClosingTime] = useState(
    selectedStore?.saturdayCloseTime || null
  );
  const [location, setLocation] = useState(selectedStore?.location || "");
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [storeImage, setStoreImage] = useState(null);
  const [compreseStoreImage, setCompereseStoreImage] = useState(null);
  const [areaImage, setAreaImage] = useState(null);
  const [compresAreaImage, setCompreseAreaImage] = useState(null);
  const [sundayStatus, setSundayStatus] = useState(selectedStore?.sundayStatus);
  const [mondayStatus, setMondayStatus] = useState(selectedStore?.mondayStatus);
  const [tuesdayStatus, setTuesdayStatus] = useState(
    selectedStore?.tuesdayStatus
  );
  const [wednesdayStatus, setWednesdayStatus] = useState(
    selectedStore?.wednesdayStatus
  );
  const [thursdayStatus, setThursdayStatus] = useState(
    selectedStore?.thursdayStatus
  );
  const [fridayStatus, setFridayStatus] = useState(selectedStore?.fridayStatus);
  const [saturdayStatus, setSaturdayStatus] = useState(
    selectedStore?.saturdayStatus
  );

  const [sundayFull, setSundayFull] = useState(
    selectedStore?.sundayFull || false
  );
  const [mondayFull, setMondayFull] = useState(
    selectedStore?.mondayFull || false
  );
  const [tuesdayFull, setTuesdayFull] = useState(
    selectedStore?.tuesdayFull || false
  );
  const [wednesdayFull, setWednesdayFull] = useState(
    selectedStore?.wednesdayFull || false
  );
  const [thursdayFull, setThursdayFull] = useState(
    selectedStore?.thursdayFull || false
  );
  const [fridayFull, setFridayFull] = useState(
    selectedStore?.fridayFull || false
  );
  const [saturdayFull, setSaturdayFull] = useState(
    selectedStore?.saturdayFull || false
  );

  const handleSundayStatus = () => {
    if (sundayStatus === false) {
      return setSundayStatus(true);
    }
    return setSundayStatus(false);
  };
  const handleMondayStatus = () => {
    if (mondayStatus === true) {
      return setMondayStatus(false);
    }
    return setMondayStatus(true);
  };
  const handleTuesdayStatus = () => {
    if (tuesdayStatus === false) {
      return setTuesdayStatus(true);
    }
    return setTuesdayStatus(false);
  };
  const handleWednesdayStatus = () => {
    if (wednesdayStatus === false) {
      return setWednesdayStatus(true);
    }
    return setWednesdayStatus(false);
  };
  const handleThursdayStatus = () => {
    if (thursdayStatus === false) {
      return setThursdayStatus(true);
    }
    return setThursdayStatus(false);
  };
  const handleFridayStatus = () => {
    if (fridayStatus === false) {
      return setFridayStatus(true);
    }
    return setFridayStatus(false);
  };
  const handleSaturdayStatus = () => {
    if (saturdayStatus === false) {
      return setSaturdayStatus(true);
    }
    return setSaturdayStatus(false);
  };

  const handleSundayFull = () => {
    if (sundayFull === false) {
      return setSundayFull(true);
    }
    return setSundayFull(false);
  };
  const handleMondayFull = () => {
    if (mondayFull === false) {
      return setMondayFull(true);
    }
    return setMondayFull(false);
  };
  const handleTuesdayFull = () => {
    if (tuesdayFull === false) {
      return setTuesdayFull(true);
    }
    return setTuesdayFull(false);
  };
  const handleWednesdayFull = () => {
    if (wednesdayFull === false) {
      return setWednesdayFull(true);
    }
    return setWednesdayFull(false);
  };
  const handleThursdayFull = () => {
    if (thursdayFull === false) {
      return setThursdayFull(true);
    }
    return setThursdayFull(false);
  };
  const handleFridayFull = () => {
    if (fridayFull === false) {
      return setFridayFull(true);
    }
    return setFridayFull(false);
  };
  const handleSaturdayFull = () => {
    if (saturdayFull === false) {
      return setSaturdayFull(true);
    }
    return setSaturdayFull(false);
  };
  const finishFirstStep = () => {
    setStep1(false);
    setStep2(true);
  };

  // -----

  const [sundaySameTime, setSundaySameTime] = useState(null);
  const [sundayTimeError, setSundayTimeError] = useState(null);

  const [mondaySameTime, setMondaySameTime] = useState(null);
  const [mondayTimeError, setMondayTimeError] = useState(null);

  const [tuesdaySameTime, setTuesdaySameTime] = useState(null);
  const [tuesdayTimeError, setTuesdayTimeError] = useState(null);

  const [wednesdaySameTime, setWednesdaySameTime] = useState(null);
  const [wednesdayTimeError, setWednesdayTimeError] = useState(null);

  const [thursdaySameTime, setThursdaySameTime] = useState(null);
  const [thursdayTimeError, setThursdayTimeError] = useState(null);

  const [fridaySameTime, setFridaySameTime] = useState(null);
  const [fridayTimeError, setFridayTimeError] = useState(null);

  const [saturdaySameTime, setSaturdaySameTime] = useState(null);
  const [saturdayTimeError, setSaturdayTimeError] = useState(null);

  // ----======--->

  const sundayOpeningFormat = formatTimeByHourAndMinute(
    sundayOpeningTime?.$H,
    sundayOpeningTime?.$m
  );
  const sundayCloseingFormat = formatTimeByHourAndMinute(
    sundayClosingTime?.$H,
    sundayClosingTime?.$m
  );
  const isSundayOpeningTimeNull =
    sundayOpeningTime === null || sundayOpeningFormat;
  const isSundayClosingTimeNull =
    sundayClosingTime === null || sundayCloseingFormat;
  const notSundayFull = !sundayFull;
  // end of sunday declaration--->
  const mondayOpeningFormat = formatTimeByHourAndMinute(
    mondayOpeningTime?.$H,
    mondayOpeningTime?.$m
  );
  const mondayCloseingFormat = formatTimeByHourAndMinute(
    mondayClosingTime?.$H,
    mondayClosingTime?.$m
  );
  const isMondayOpeningTimeNull =
    mondayOpeningTime === null || mondayOpeningFormat;
  const isMondayClosingTimeNull =
    mondayClosingTime === null || mondayCloseingFormat;
  const notMondayFull = !mondayFull;

  // end of monday declaration

  const tuesdayOpeningFormat = formatTimeByHourAndMinute(
    tuesdayOpeningTime?.$H,
    tuesdayOpeningTime?.$m
  );
  const tuesdayCloseingFormat = formatTimeByHourAndMinute(
    tuesdayClosingTime?.$H,
    tuesdayClosingTime?.$m
  );
  const isTuesdayOpeningTimeNull =
    tuesdayOpeningTime === null || tuesdayOpeningFormat;
  const isTuesdayClosingTimeNull =
    tuesdayClosingTime === null || tuesdayCloseingFormat;
  const notTuesdayFull = !sundayFull;
  // end of tuesday declaration--->
  const wednesdayOpeningFormat = formatTimeByHourAndMinute(
    wednesdayOpeningTime?.$H,
    wednesdayOpeningTime?.$m
  );
  const wednesdayCloseingFormat = formatTimeByHourAndMinute(
    wednesdayClosingTime?.$H,
    wednesdayClosingTime?.$m
  );
  const isWednesdayOpeningTimeNull =
    wednesdayOpeningTime === null || wednesdayOpeningFormat;
  const isWednesdayClosingTimeNull =
    wednesdayClosingTime === null || wednesdayCloseingFormat;
  const notWednesdayFull = !wednesdayFull;
  // end of wenday declaration
  const thursdayOpeningFormat = formatTimeByHourAndMinute(
    thursdayOpeningTime?.$H,
    thursdayOpeningTime?.$m
  );
  const thursdayClosingFormat = formatTimeByHourAndMinute(
    thursdayClosingTime?.$H,
    thursdayClosingTime?.$m
  );
  const isThursdayOpeningTimeNull =
    thursdayOpeningTime === null || thursdayOpeningFormat;
  const isThursdayClosingTimeNull =
    thursdayClosingTime === null || thursdayClosingFormat;
  const notThursdayFull = !thursdayFull;
  // end of thursday declaration
  const fridayOpeningFormat = formatTimeByHourAndMinute(
    fridayOpeningTime?.$H,
    fridayOpeningTime?.$m
  );
  const fridayClosingFormat = formatTimeByHourAndMinute(
    fridayClosingTime?.$H,
    fridayClosingTime?.$m
  );
  const isFridayOpeningTimeNull =
    fridayOpeningTime === null || fridayOpeningFormat;
  const isFridayClosingTimeNull =
    fridayClosingTime === null || fridayClosingFormat;
  const notFridayFull = !fridayFull;
  // end of friday declaration
  const saturdayOpeningFormat = formatTimeByHourAndMinute(
    saturdayOpeningTime?.$H,
    saturdayOpeningTime?.$m
  );
  const saturdayClosingFormat = formatTimeByHourAndMinute(
    saturdayClosingTime?.$H,
    saturdayClosingTime?.$m
  );
  const isSaturdayOpeningTimeNull =
    saturdayOpeningTime === null || saturdayOpeningFormat;
  const isSaturdayClosingTimeNull =
    saturdayClosingTime === null || saturdayClosingFormat;
  const notSaturdayFull = !saturdayFull;
  // end of saturday declaration

  useEffect(() => {
    if (
      (sundayOpeningTime === "undefined:undefined" ||
        sundayOpeningTime === null ||
        sundayClosingTime === "undefined:undefined" ||
        sundayClosingTime === null) &&
      sundayFull === false
    ) {
      return setSundayTimeError(true);
    } else {
      setSundayTimeError(false);
    }
    if (!sundayFull && sundayStatus) {
      if (
        (sundayCloseingFormat !== "undefined:undefined" &&
          sundayOpeningTime === sundayCloseingFormat) ||
        (sundayOpeningFormat !== "undefined:undefined" &&
          sundayClosingTime === sundayOpeningFormat) ||
        sundayOpeningTime === sundayClosingTime ||
        (sundayOpeningFormat !== "undefined:undefined" &&
          sundayCloseingFormat !== "undefined:undefined" &&
          sundayOpeningFormat === sundayCloseingFormat)
      ) {
        setSundaySameTime(true);
      } else {
        setSundaySameTime(false);
      }
    }
    // end of sunday ---->
    if (
      (mondayOpeningTime === "undefined:undefined" ||
        mondayOpeningTime === null ||
        mondayClosingTime === "undefined:undefined" ||
        mondayClosingTime === null) &&
      mondayFull === false
    ) {
      return setMondayTimeError(true);
    } else {
      setMondayTimeError(false);
    }
    if (!mondayFull && mondayStatus) {
      if (
        (mondayCloseingFormat !== "undefined:undefined" &&
          mondayOpeningTime === mondayCloseingFormat) ||
        (mondayOpeningFormat !== "undefined:undefined" &&
          mondayClosingTime === mondayOpeningFormat) ||
        mondayOpeningTime === mondayClosingTime ||
        (mondayOpeningFormat !== "undefined:undefined" &&
          mondayCloseingFormat !== "undefined:undefined" &&
          mondayOpeningFormat === mondayCloseingFormat)
      ) {
        setMondaySameTime(true);
      } else {
        setMondaySameTime(false);
      }
    }
    // end of monday--->
    if (
      (tuesdayOpeningTime === "undefined:undefined" ||
        tuesdayOpeningTime === null ||
        tuesdayClosingTime === "undefined:undefined" ||
        tuesdayClosingTime === null) &&
      tuesdayFull === false
    ) {
      return setTuesdayTimeError(true);
    } else {
      setTuesdayTimeError(false);
    }
    if (!tuesdayFull && tuesdayStatus) {
      if (
        (tuesdayCloseingFormat !== "undefined:undefined" &&
          tuesdayOpeningTime === tuesdayCloseingFormat) ||
        (tuesdayOpeningFormat !== "undefined:undefined" &&
          tuesdayClosingTime === tuesdayOpeningFormat) ||
        tuesdayOpeningTime === tuesdayClosingTime ||
        (tuesdayOpeningFormat !== "undefined:undefined" &&
          tuesdayCloseingFormat !== "undefined:undefined" &&
          tuesdayOpeningFormat === tuesdayCloseingFormat)
      ) {
        setTuesdaySameTime(true);
      } else {
        setTuesdaySameTime(false);
      }
    }
    // end of tuesday---->
    if (
      (wednesdayOpeningTime === "undefined:undefined" ||
        wednesdayOpeningTime === null ||
        wednesdayClosingTime === "undefined:undefined" ||
        wednesdayClosingTime === null) &&
      wednesdayFull === false
    ) {
      return setWednesdayTimeError(true);
    } else {
      setWednesdayTimeError(false);
    }
    if (!wednesdayFull && wednesdayStatus) {
      if (
        (wednesdayCloseingFormat !== "undefined:undefined" &&
          wednesdayOpeningTime === wednesdayCloseingFormat) ||
        (wednesdayOpeningFormat !== "undefined:undefined" &&
          wednesdayClosingTime === wednesdayOpeningFormat) ||
        wednesdayOpeningTime === wednesdayClosingTime ||
        (wednesdayOpeningFormat !== "undefined:undefined" &&
          wednesdayCloseingFormat !== "undefined:undefined" &&
          wednesdayOpeningFormat === wednesdayCloseingFormat)
      ) {
        setWednesdaySameTime(true);
      } else {
        setWednesdaySameTime(false);
      }
    }
    // end of wednesday ---
    if (
      (thursdayOpeningTime === "undefined:undefined" ||
        thursdayOpeningTime === null ||
        thursdayClosingTime === "undefined:undefined" ||
        thursdayClosingTime === null) &&
      thursdayFull === false
    ) {
      return setThursdayTimeError(true);
    } else {
      setThursdayTimeError(false);
    }
    if (!thursdayFull && thursdayStatus) {
      if (
        (thursdayClosingFormat !== "undefined:undefined" &&
          thursdayOpeningTime === thursdayClosingFormat) ||
        (thursdayOpeningFormat !== "undefined:undefined" &&
          thursdayClosingTime === thursdayOpeningFormat) ||
        thursdayOpeningTime === thursdayClosingTime ||
        (thursdayOpeningFormat !== "undefined:undefined" &&
          thursdayClosingFormat !== "undefined:undefined" &&
          thursdayOpeningFormat === thursdayClosingFormat)
      ) {
        setThursdaySameTime(true);
      } else {
        setThursdaySameTime(false);
      }
    }

    // end of thursday
    if (
      (fridayOpeningTime === "undefined:undefined" ||
        fridayOpeningTime === null ||
        fridayClosingTime === "undefined:undefined" ||
        fridayClosingTime === null) &&
      fridayFull === false
    ) {
      return setFridayTimeError(true);
    } else {
      setFridayTimeError(false);
    }
    if (!fridayFull && fridayStatus) {
      if (
        (fridayClosingFormat !== "undefined:undefined" &&
          fridayOpeningTime === fridayClosingFormat) ||
        (fridayOpeningFormat !== "undefined:undefined" &&
          fridayClosingTime === fridayOpeningFormat) ||
        fridayOpeningTime === fridayClosingTime ||
        (fridayOpeningFormat !== "undefined:undefined" &&
          fridayClosingFormat !== "undefined:undefined" &&
          fridayOpeningFormat === fridayClosingFormat)
      ) {
        setFridaySameTime(true);
      } else {
        setFridaySameTime(false);
      }
    }
    // end of friday --->
    if (
      (saturdayOpeningTime === "undefined:undefined" ||
        saturdayOpeningTime === null ||
        saturdayClosingTime === "undefined:undefined" ||
        saturdayClosingTime === null) &&
      saturdayFull === false
    ) {
      return setSaturdayTimeError(true);
    } else {
      setSaturdayTimeError(false);
    }
    if (!saturdayFull && saturdayStatus) {
      if (
        (saturdayClosingFormat !== "undefined:undefined" &&
          saturdayOpeningTime === saturdayClosingFormat) ||
        (saturdayOpeningFormat !== "undefined:undefined" &&
          saturdayClosingTime === saturdayOpeningFormat) ||
        saturdayOpeningTime === saturdayClosingTime ||
        (saturdayOpeningFormat !== "undefined:undefined" &&
          saturdayClosingFormat !== "undefined:undefined" &&
          saturdayOpeningFormat === saturdayClosingFormat)
      ) {
        setSaturdaySameTime(true);
      } else {
        setSaturdaySameTime(false);
      }
    }

    // end of saturday--->
  }, [
    notSundayFull,
    isSundayOpeningTimeNull,
    isSundayClosingTimeNull,
    sundayOpeningTime,
    sundayClosingTime,
    sundayFull,
    step2,
    sundayCloseingFormat,
    sundayOpeningFormat,
    sundayStatus,
    // end sunday
    notMondayFull,
    isMondayOpeningTimeNull,
    isMondayClosingTimeNull,
    mondayOpeningTime,
    mondayClosingTime,
    mondayFull,
    step2,
    mondayCloseingFormat,
    mondayOpeningFormat,
    mondayStatus,
    // end monday
    tuesdayCloseingFormat,
    tuesdayClosingTime,
    tuesdayFull,
    tuesdayOpeningFormat,
    tuesdayOpeningTime,
    tuesdayStatus,
    isTuesdayOpeningTimeNull,
    isTuesdayClosingTimeNull,
    notTuesdayFull,
    // tuesday
    wednesdayCloseingFormat,
    wednesdayClosingTime,
    wednesdayFull,
    wednesdayOpeningFormat,
    wednesdayOpeningTime,
    wednesdayStatus,
    isWednesdayOpeningTimeNull,
    isWednesdayClosingTimeNull,
    notWednesdayFull,
    // end of wednesday
    thursdayClosingFormat,
    thursdayClosingTime,
    thursdayFull,
    thursdayOpeningFormat,
    thursdayOpeningTime,
    thursdayStatus,
    isThursdayOpeningTimeNull,
    isThursdayClosingTimeNull,
    notThursdayFull,
    // end of thursday
    fridayClosingFormat,
    fridayClosingTime,
    fridayFull,
    fridayOpeningFormat,
    fridayOpeningTime,
    fridayStatus,
    isFridayOpeningTimeNull,
    isFridayClosingTimeNull,
    notFridayFull,
    // end of friday
    saturdayClosingFormat,
    saturdayClosingTime,
    saturdayFull,
    saturdayOpeningFormat,
    saturdayOpeningTime,
    saturdayStatus,
    isSaturdayOpeningTimeNull,
    isSaturdayClosingTimeNull,
    notSaturdayFull,
  ]);
  // button disable
  let isError = false;
  if (
    sundaySameTime ||
    sundayTimeError ||
    mondaySameTime ||
    mondayTimeError ||
    tuesdaySameTime ||
    tuesdayTimeError ||
    wednesdaySameTime ||
    wednesdayTimeError ||
    thursdaySameTime ||
    thursdayTimeError ||
    fridaySameTime ||
    fridayTimeError ||
    saturdaySameTime ||
    saturdayTimeError
  ) {
    isError = true;
  }

  const [errors, setErrors] = useState({
    sundayEqual: false,
    sundayNewEqualStored: false,
    mondayEqual: false,
    mondayNewEqualStored: false,
    tuesdayEqual: false,
    tuesdayNewEqualStored: false,
    wednesdayEqual: false,
    wednesdayNewEqualStored: false,
    thursdayEqual: false,
    thursdayNewEqualStored: false,
    fridayEqual: false,
    fridayNewEqualStored: false,
    saturdayEqual: false,
    saturdayNewEqualStored: false,
  });

  const formatTime = (time) => (time ? dayjs(time).format("HH:mm") : "");

  const getFormattedTime = (timeString) =>
    timeString ? dayjs(timeString, "HH:mm") : null;

  const validateTimesForDay = (
    day,
    openingTime,
    closingTime,
    selectedStore
  ) => {
    const dayErrors = {};

    if (openingTime && closingTime) {
      dayErrors[`${day}Equal`] =
        formatTime(openingTime) === formatTime(closingTime);

      const storeOpeningTime = getFormattedTime(
        selectedStore?.[`${day}StrtTime`]
      );
      const storeClosingTime = getFormattedTime(
        selectedStore?.[`${day}CloseTime`]
      );

      dayErrors[`${day}NewEqualStored`] =
        formatTime(openingTime) === formatTime(storeClosingTime) &&
        formatTime(closingTime) === formatTime(storeOpeningTime);
    }

    return dayErrors;
  };

  const finishSecondStep = () => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    const newErrors = {};

    days.forEach((day) => {
      const openingTime =
        day === "sunday"
          ? sundayOpeningTime
          : day === "monday"
          ? mondayOpeningTime
          : day === "tuesday"
          ? tuesdayOpeningTime
          : day === "wednesday"
          ? wednesdayOpeningTime
          : day === "thursday"
          ? thursdayOpeningTime
          : day === "friday"
          ? fridayOpeningTime
          : day === "saturday"
          ? saturdayOpeningTime
          : null;
      const closingTime =
        day === "sunday"
          ? sundayClosingTime
          : day === "monday"
          ? mondayClosingTime
          : day === "tuesday"
          ? tuesdayClosingTime
          : day === "wednesday"
          ? wednesdayClosingTime
          : day === "thursday"
          ? thursdayClosingTime
          : day === "friday"
          ? fridayClosingTime
          : day === "saturday"
          ? saturdayClosingTime
          : null;
      const dayErrors = validateTimesForDay(
        day,
        openingTime,
        closingTime,
        selectedStore
      );

      Object.assign(newErrors, dayErrors);
    });

    setStep2(false);
    setStep3(true);
  };

  useEffect(() => {
    console.log("Current Errors state:", errors);
  }, [errors]);

  const finishThirdStep = () => {
    setStep3(false);
    setStep4(true);
  };

  const handleStoreImage = async (e) => {
    setStoreImage(e);
    const imageFile = e;
    if (!imageFile) return;
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      setCompereseStoreImage(compressedFile);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  const handleAreImage = async (e) => {
    setAreaImage(e);
    const imageFile = e;
    if (!imageFile) return;
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      setCompreseAreaImage(compressedFile);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  useEffect(() => {
    if (provider) {
      setProviderName(provider.name);
      setProviderEmail(provider.email);
      setProviderID(provider.id);
      setProviderMobile(provider.mobile);
    }
  }, [provider]);
  const inputRef = useRef();
  const handlePaceChange = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setMapLan(place.geometry.location.lng());
      setMapLat(place.geometry.location.lat());
      setLocation(place.formatted_address);
    }
  };

  const handleSubmit = async () => {
    const sundayStrtTime = await formatTimeByHourAndMinute(
      sundayOpeningTime?.$H,
      sundayOpeningTime?.$m
    );
    const sundayCloseTime = await formatTimeByHourAndMinute(
      sundayClosingTime?.$H,
      sundayClosingTime?.$m
    );
    const mondayStrtTime = await formatTimeByHourAndMinute(
      mondayOpeningTime?.$H,
      mondayOpeningTime?.$m
    );
    const mondayCloseTime = await formatTimeByHourAndMinute(
      mondayClosingTime?.$H,
      mondayClosingTime?.$m
    );
    const tuesdayStrtTime = await formatTimeByHourAndMinute(
      tuesdayOpeningTime?.$H,
      tuesdayOpeningTime?.$m
    );
    const tuesdayCloseTime = await formatTimeByHourAndMinute(
      tuesdayClosingTime?.$H,
      tuesdayClosingTime?.$m
    );
    const wednesdayStrtTime = await formatTimeByHourAndMinute(
      wednesdayOpeningTime?.$H,
      wednesdayOpeningTime?.$m
    );
    const wednesdayCloseTime = await formatTimeByHourAndMinute(
      wednesdayClosingTime?.$H,
      wednesdayClosingTime?.$m
    );
    const thursdayStrtTime = await formatTimeByHourAndMinute(
      thursdayOpeningTime?.$H,
      thursdayOpeningTime?.$m
    );
    const thursdayCloseTime = await formatTimeByHourAndMinute(
      thursdayClosingTime?.$H,
      thursdayClosingTime?.$m
    );
    const fridayStrtTime = await formatTimeByHourAndMinute(
      fridayOpeningTime?.$H,
      fridayOpeningTime?.$m
    );
    const fridayCloseTime = await formatTimeByHourAndMinute(
      fridayClosingTime?.$H,
      fridayClosingTime?.$m
    );
    const saturdayStrtTime = await formatTimeByHourAndMinute(
      saturdayOpeningTime?.$H,
      saturdayOpeningTime?.$m
    );
    const saturdayCloseTime = await formatTimeByHourAndMinute(
      saturdayClosingTime?.$H,
      saturdayClosingTime?.$m
    );

    const formDataToSend = new FormData();
    formDataToSend.append("businessName", storeName);
    formDataToSend.append("providerName", providerName);
    formDataToSend.append("providerEmail", providerEmail);
    formDataToSend.append("providerID", providerID);
    formDataToSend.append("providerMobile", providerMobile);
    formDataToSend.append("countryName", countryName);
    formDataToSend.append("cityName", cityName);
    formDataToSend.append("mapLat", mapLat);
    formDataToSend.append("mapLan", mapLan);
    formDataToSend.append("address", location);
    formDataToSend.append("zipCode", zipCode);
    formDataToSend.append("sundayStrtTime", sundayStrtTime);
    formDataToSend.append("sundayCloseTime", sundayCloseTime);
    formDataToSend.append("mondayStrtTime", mondayStrtTime);
    formDataToSend.append("mondayCloseTime", mondayCloseTime);
    formDataToSend.append("tuesdayStrtTime", tuesdayStrtTime);
    formDataToSend.append("tuesdayCloseTime", tuesdayCloseTime);
    formDataToSend.append("wednesdayStrtTime", wednesdayStrtTime);
    formDataToSend.append("wednesdayCloseTime", wednesdayCloseTime);
    formDataToSend.append("thursdayStrtTime", thursdayStrtTime);
    formDataToSend.append("thursdayCloseTime", thursdayCloseTime);
    formDataToSend.append("fridayStrtTime", fridayStrtTime);
    formDataToSend.append("fridayCloseTime", fridayCloseTime);
    formDataToSend.append("saturdayStrtTime", saturdayStrtTime);
    formDataToSend.append("saturdayCloseTime", saturdayCloseTime);
    formDataToSend.append("bussinessType", bussinessType);
    formDataToSend.append("storeImage", compreseStoreImage);
    formDataToSend.append("areaImage", compresAreaImage);
    formDataToSend.append("sundayFull", sundayFull);
    formDataToSend.append("mondayFull", mondayFull);
    formDataToSend.append("tuesdayFull", tuesdayFull);
    formDataToSend.append("wednesdayFull", wednesdayFull);
    formDataToSend.append("thursdayFull", thursdayFull);
    formDataToSend.append("fridayFull", fridayFull);
    formDataToSend.append("saturdayFull", saturdayFull);
    formDataToSend.append("sundayStatus", sundayStatus);
    formDataToSend.append("mondayStatus", mondayStatus);
    formDataToSend.append("tuesdayStatus", tuesdayStatus);
    formDataToSend.append("wednesdayStatus", wednesdayStatus);
    formDataToSend.append("thursdayStatus", thursdayStatus);
    formDataToSend.append("fridayStatus", fridayStatus);
    formDataToSend.append("saturdayStatus", saturdayStatus);
    dispatch(
      updateStore({ storeId: currentStore, token, data: formDataToSend })
    );

    navigate("/");
  };
  //  end of handlesubmit==>
    useEffect(() => {
      // This effect will run whenever selectedStore changes
      if (selectedStore && selectedStore.id) {
        console.log('Selected store changed:', selectedStore);
        // Perform any other actions needed when selectedStore changes
       
      }
    }, [selectedStore]);
  return (
    <Layout>
      <div className=" flex flex-col justify-center mt-11">
        {step1 === true && (
          <div className=" flex flex-col justify-center  p-[3px]  w-full sm:w-[98%] md:w-[95%] lg:w-[90%] xl:w-[85%] 2xl:w-[85%] mx-auto">
            <div className="w-full mt-[10px] ">
              <div className="   mb-[20px] font-semibold text-black text-xl max-sm:text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl">
                Business Information
              </div>
              <div className=" ">
                <div>
                  <label className="text-left text-[#666666] block mb-[20px] ">
                    Name
                  </label>
                </div>
                <TextField
                  className="w-[98%] p-2 mt-[10px] "
                  id="storeName"
                  placeholder="Business Name"
                  variant="outlined"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  required
                  InputProps={{
                    style: { color: "#666666", marginTop: "-15px" },
                  }}
                  inputProps={{
                    style: { color: "#666666" },
                  }}
                />
              </div>

              <div>
                <label className="text-left text-[#666666] block mb-2 mt-2">
                  Country
                </label>
                <TextField
                  className="w-[98%] p-2 "
                  id="fkCountry"
                  name="fkCountry"
                  disabled
                  select
                  value={countryName}
                  onChange={(e) => setCountryName(e.target.value)}
                  variant="outlined"
                >
                  {countries.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <br />
              <div>
                <div>
                  <label
                    className="text-left text-[#666666] block"
                    style={{ marginTop: "-15px" }}
                  >
                    City
                  </label>
                </div>

                <br />
                <TextField
                  className="w-[98%] p-2  "
                  id="fkCity"
                  name="fkCity"
                  disabled
                  style={{ marginTop: "-15px", marginBottom: "-15px" }}
                  select
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                  variant="outlined"
                  required
                >
                  {cityArr?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <br />
              <div>
                <div>
                  <label
                    className="text-left text-[#666666]"
                    style={{ marginBottom: "-15px" }}
                  >
                    Address
                  </label>
                </div>
                <br />
                <LoadScript
                  googleMapsApiKey="AIzaSyBxgJmS9RNOnpiasONTvGUGgS7GRGsH7cg"
                  libraries={["places"]}
                >
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={handlePaceChange}
                  >
                    <input
                      className="text-left w-[98%] form-control border-solid-1 h-[50px] border-redius-[20px] "
                      type="text"
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        marginTop: "-15px",
                        padding: "5px",
                      }}
                      placeholder="House - 02, Street - 04, Avenue - B, Banasree"
                      required
                    />
                  </StandaloneSearchBox>
                </LoadScript>
              </div>
              <div>
                <div className="mt-[10px] ">
                  <label className="text-left text-[#666666]  ">Postcode</label>
                </div>
                <br />
                <TextField
                  className="w-[98%] p-2  text-[#666666] -mt-[15px] "
                  id="zipCode"
                  placeholder="Post code"
                  variant="outlined"
                  value={zipCode}
                  style={{ marginTop: "-15px" }}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                  InputProps={{
                    style: { color: "#666666" },
                  }}
                  inputProps={{
                    style: { color: "#666666" },
                  }}
                />
              </div>
              <div>
                <div className="mt-[10px] ">
                  <label className="text-left text-[#666666]">
                    Business Type
                  </label>
                </div>

                <br />
                <TextField
                  className="w-[98%] p-2"
                  id="businessType"
                  placeholder="Business Type"
                  variant="outlined"
                  style={{ marginTop: "-15px" }}
                  value={bussinessType}
                  onChange={(e) => setBussinessType(e.target.value)}
                  required
                  InputProps={{
                    style: { color: "#666666" },
                  }}
                  inputProps={{
                    style: { color: "#666666" },
                  }}
                />
              </div>
              <br />
              <div className="text-center mb-[60px]  mr-4 ">
                <Button
                  onClick={finishFirstStep}
                  style={{ backgroundColor: "black", color: "white" }}
                  className="w-[100%] h-auto  mb-16  rounded-2xl cursor-pointer"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
        {/* end of firs page side==> */}

        {step2 && (
          <div className=" w-full sm:w-[98%] md:w-[98%] lg:w-[98%] xl:w-[90%] 2xl:w-[90%] mb-11 p-4 mx-auto ">
            <div className="w-full justify-center items-center  ">
              <h3 className="mt-[12px]   font-semibold ml-[2px]  text-blacktext-2xl md:text-2xl">
                Business Hour
              </h3>

              <div className="mt-[20px] ">
                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-10 ">
                  <div className="w-[170px] flex justify-start">
                    <FormControlLabel
                      onClick={handleSundayStatus}
                      control={
                        <IOSSwitch
                          className="ml-[10px] m-1"
                          checked={sundayStatus}
                        />
                      }
                      label="Sunday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center ml-[5px]">
                    {sundayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleSundayFull}
                            defaultChecked={sundayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {sundayStatus && (
                  <div className="p-1">
                    {!sundayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          className="border p-[1px] h-[14px]"
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              !errors.sundayEqual ||
                              !errors.sundayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.sundayStrtTime
                            )}
                            onChange={(time) => {
                              setSundayOpeningTime(time);
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.sundayEqual || errors.sundayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.sundayCloseTime
                            )}
                            onChange={(time) => {
                              setSundayClosingTime(time);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}
                    {sundayTimeError && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {sundaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                    {errors.sundayNewEqualStored && (
                      <Typography className="text-red-500" variant="caption">
                        New opening or closing time cannot be the same as the
                        old opening or closing time.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of sunday ===>> */}

              <div className=" ">
                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-10">
                  <div className="w-[170px] flex justify-start   ">
                    <FormControlLabel
                      onClick={handleMondayStatus}
                      control={
                        <IOSSwitch
                          className=" ml-[10px] m-1"
                          checked={mondayStatus}
                        />
                      }
                      label="Monday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center   ml-[5px] ">
                    {mondayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleMondayFull}
                            defaultChecked={mondayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {mondayStatus && (
                  <div className="p-1">
                    {!mondayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          className="border border-gray-400 p-[1px] h-[14px]"
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.mondayEqual || errors.mondayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.mondayStrtTime
                            )}
                            onChange={(time) => {
                              setMondayOpeningTime(time);
                              const isSameAsClosing =
                                time &&
                                mondayClosingTime &&
                                formatTime(time) ===
                                  formatTime(mondayClosingTime);
                              const isSameAsStoredClosing =
                                time &&
                                selectedStore?.mondayCloseTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.mondayCloseTime
                                    )
                                  );

                              if (isSameAsClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEqual: true,
                                  mondayNewEqualStored: false,
                                }));
                              } else if (isSameAsStoredClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEqual: false,
                                  mondayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEqual: false,
                                  mondayNewEqualStored: false,
                                }));
                              }
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.mondayEqual || errors.mondayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.mondayCloseTime
                            )}
                            onChange={(time) => {
                              setMondayClosingTime(time);
                              const isSameAsOpening =
                                mondayOpeningTime &&
                                time &&
                                formatTime(mondayOpeningTime) ===
                                  formatTime(time);
                              const isSameAsStoredOpening =
                                time &&
                                selectedStore?.mondayStrtTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.mondayStrtTime
                                    )
                                  );

                              if (isSameAsOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEqual: true,
                                  mondayNewEqualStored: false,
                                }));
                              } else if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEqual: false,
                                  mondayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEqual: false,
                                  mondayNewEqualStored: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}
                    {mondayTimeError && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {mondaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                    {errors.mondayNewEqualStored && (
                      <Typography className="text-red-500" variant="caption">
                        New opening time cannot be the same as the old closing
                        time.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of monday ===>> */}
              <div className="">
                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-10">
                  <div className="w-[170px] flex justify-start    ">
                    <FormControlLabel
                      onClick={handleTuesdayStatus}
                      control={
                        <IOSSwitch
                          className="  ml-[10px] m-1"
                          checked={tuesdayStatus}
                        />
                      }
                      label="Tuesday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center  ml-[5px] ">
                    {tuesdayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleTuesdayFull}
                            defaultChecked={tuesdayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {tuesdayStatus && (
                  <div className="p-1">
                    {!tuesdayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                          className="w-full md:w-3/4 mx-auto"
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.tuesdayEqual ||
                              errors.tuesdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.tuesdayStrtTime
                            )}
                            onChange={(time) => {
                              setTuesdayOpeningTime(time);
                              const isSameAsClosing =
                                time &&
                                tuesdayClosingTime &&
                                formatTime(time) ===
                                  formatTime(tuesdayClosingTime);
                              const isSameAsStoredClosing =
                                time &&
                                selectedStore?.tuesdayCloseTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.tuesdayCloseTime
                                    )
                                  );

                              if (isSameAsClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEqual: true,
                                  tuesdayNewEqualStored: false,
                                }));
                              }

                              if (isSameAsStoredClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEqual: false,
                                  tuesdayNewEqualStored: true,
                                }));
                              }
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.tuesdayEqual ||
                              errors.tuesdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.tuesdayCloseTime
                            )}
                            onChange={(time) => {
                              setTuesdayClosingTime(time);
                              const isSameAsOpening =
                                tuesdayOpeningTime &&
                                time &&
                                formatTime(tuesdayOpeningTime) ===
                                  formatTime(time);
                              const isSameAsStoredOpening =
                                time &&
                                selectedStore?.tuesdayStrtTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.tuesdayStrtTime
                                    )
                                  );

                              if (isSameAsOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEqual: true,
                                  tuesdayNewEqualStored: false,
                                }));
                              }
                              if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEqual: false,
                                  tuesdayNewEqualStored: true,
                                }));
                              }
                              if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEqual: false,
                                  tuesdayNewEqualStored: true,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}

                    {/* Error messages */}
                    {errors.tuesdayTimeError && (
                      <Typography
                        className="text-red-500 p-2 bg-slate-400 rounded-3xl"
                        variant="caption"
                      >
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {errors.tuesdaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                    {errors.tuesdayNewEqualStored && (
                      <Typography className="text-red-500" variant="caption">
                        New opening time cannot be the same as the old closing
                        time.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of tuesday ====> */}
              <div className="">
                <div className="flex flex-col md:flex-row justify-start md:gap-10">
                  <div className="w-[170px]  flex justify-start mr-[5px]  ">
                    <FormControlLabel
                      onClick={handleWednesdayStatus}
                      control={
                        <IOSSwitch
                          className="m-1  ml-[10px]"
                          checked={wednesdayStatus}
                        />
                      }
                      label="Wednesday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center  ">
                    {wednesdayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleWednesdayFull}
                            defaultChecked={wednesdayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {wednesdayStatus && (
                  <div className="p-1 mt-1">
                    {!wednesdayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                          className="w-full md:w-3/4 mx-auto"
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.wednesdayEqual ||
                              errors.wednesdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.wednesdayStrtTime
                            )}
                            onChange={(time) => {
                              setWednesdayOpeningTime(time);
                              const isSameAsClosing =
                                time &&
                                wednesdayClosingTime &&
                                formatTime(time) ===
                                  formatTime(wednesdayClosingTime);
                              const isSameAsStoredClosing =
                                time &&
                                selectedStore?.wednesdayCloseTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.wednesdayCloseTime
                                    )
                                  );

                              if (isSameAsClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEqual: true,
                                  wednesdayNewEqualStored: false,
                                }));
                              } else if (isSameAsStoredClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEqual: false,
                                  wednesdayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEqual: false,
                                  wednesdayNewEqualStored: false,
                                }));
                              }
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.wednesdayEqual ||
                              errors.wednesdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.wednesdayCloseTime
                            )}
                            onChange={(time) => {
                              setWednesdayClosingTime(time);
                              const isSameAsOpening =
                                wednesdayOpeningTime &&
                                time &&
                                formatTime(wednesdayOpeningTime) ===
                                  formatTime(time);
                              const isSameAsStoredOpening =
                                time &&
                                selectedStore?.wednesdayStrtTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.wednesdayStrtTime
                                    )
                                  );

                              if (isSameAsOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEqual: true,
                                  wednesdayNewEqualStored: false,
                                }));
                              }
                              if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEqual: false,
                                  wednesdayNewEqualStored: true,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}

                    {/* Error messages */}

                    {wednesdayTimeError && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {wednesdaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                    {errors.wednesdayNewEqualStored && (
                      <Typography className="text-red-500" variant="caption">
                        New opening time cannot be the same as the old closing
                        time.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of wednesday */}
              <div className="">
                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-10">
                  <div className="w-[170px] flex justify-start   ">
                    <FormControlLabel
                      onClick={handleThursdayStatus}
                      control={
                        <IOSSwitch
                          className="m-1 ml-[10px] "
                          checked={thursdayStatus}
                        />
                      }
                      label="Thursday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center ml-[5px]  ">
                    {thursdayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleThursdayFull}
                            defaultChecked={thursdayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {thursdayStatus && (
                  <div className="p-1 mt-1">
                    {!thursdayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                          className="w-full md:w-3/4 mx-auto"
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.thursdayEqual ||
                              errors.thursdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.thursdayStrtTime
                            )}
                            onChange={(time) => {
                              setThursdayOpeningTime(time);
                              const isSameAsClosing =
                                time &&
                                thursdayClosingTime &&
                                formatTime(time) ===
                                  formatTime(thursdayClosingTime);
                              const isSameAsStoredClosing =
                                time &&
                                selectedStore?.thursdayCloseTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.thursdayCloseTime
                                    )
                                  );

                              if (isSameAsClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEqual: true,
                                  thursdayNewEqualStored: false,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEqual: false,
                                }));
                              }

                              if (isSameAsStoredClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEqual: false,
                                  thursdayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayNewEqualStored: false,
                                }));
                              }
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.thursdayEqual ||
                              errors.thursdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.thursdayCloseTime
                            )}
                            onChange={(time) => {
                              setThursdayClosingTime(time);
                              const isSameAsOpening =
                                thursdayOpeningTime &&
                                time &&
                                formatTime(thursdayOpeningTime) ===
                                  formatTime(time);
                              const isSameAsStoredOpening =
                                time &&
                                selectedStore?.thursdayStrtTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.thursdayStrtTime
                                    )
                                  );

                              if (isSameAsOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEqual: true,
                                  thursdayNewEqualStored: false,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEqual: false,
                                }));
                              }

                              if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEqual: false,
                                  thursdayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayNewEqualStored: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}

                    {/* Error messages */}
                    {thursdayTimeError && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {thursdaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of thursday */}
              <div className=" ">
                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-10">
                  <div className="w-[168px] flex justify-start  ">
                    <FormControlLabel
                      onClick={handleFridayStatus}
                      control={
                        <IOSSwitch
                          className="m-1 ml-[10px] "
                          checked={fridayStatus}
                        />
                      }
                      label="Friday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center   ml-[5px] ">
                    {fridayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleFridayFull}
                            defaultChecked={fridayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {fridayStatus && (
                  <div className="p-1 mt-1">
                    {!fridayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                          className="w-full md:w-3/4 mx-auto"
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.fridayEqual || errors.fridayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.fridayStrtTime
                            )}
                            onChange={(time) => {
                              setFridayOpeningTime(time);
                              const isSameAsClosing =
                                time &&
                                fridayClosingTime &&
                                formatTime(time) ===
                                  formatTime(fridayClosingTime);
                              const isSameAsStoredClosing =
                                time &&
                                selectedStore?.fridayCloseTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.fridayCloseTime
                                    )
                                  );

                              if (isSameAsClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEqual: true,
                                  fridayNewEqualStored: false,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEqual: false,
                                }));
                              }

                              if (isSameAsStoredClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEqual: false,
                                  fridayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayNewEqualStored: false,
                                }));
                              }
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.fridayEqual || errors.fridayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.fridayCloseTime
                            )}
                            onChange={(time) => {
                              setFridayClosingTime(time);
                              const isSameAsOpening =
                                fridayOpeningTime &&
                                time &&
                                formatTime(fridayOpeningTime) ===
                                  formatTime(time);
                              const isSameAsStoredOpening =
                                time &&
                                selectedStore?.fridayStrtTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.fridayStrtTime
                                    )
                                  );

                              if (isSameAsOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEqual: true,
                                  fridayNewEqualStored: false,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEqual: false,
                                }));
                              }

                              if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEqual: false,
                                  fridayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayNewEqualStored: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}

                    {/* Error messages */}

                    {fridayTimeError && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {fridaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of friday */}
              <div className="mb-4">
                <div className="flex flex-col md:flex-row justify-start gap-5 md:gap-10">
                  <div className="w-[177px] flex justify-start  ">
                    <FormControlLabel
                      onClick={handleSaturdayStatus}
                      control={
                        <IOSSwitch
                          className="m-1 ml-[10px] "
                          checked={saturdayStatus}
                        />
                      }
                      label="Saturday"
                    />
                  </div>

                  <div className="flex flex-col items-start sm:flex-row sm:items-center    -ml-[5px] ">
                    {saturdayStatus && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleSaturdayFull}
                            defaultChecked={saturdayFull}
                          />
                        }
                        label="24hr Open"
                      />
                    )}
                  </div>
                </div>

                {saturdayStatus && (
                  <div className="p-1 mt-1">
                    {!saturdayFull && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                          className="w-full md:w-3/4 mx-auto"
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.saturdayEqual ||
                              errors.saturdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Start Time"
                            value={getFormattedTime(
                              selectedStore?.saturdayStrtTime
                            )}
                            onChange={(time) => {
                              setSaturdayOpeningTime(time);
                              const isSameAsClosing =
                                time &&
                                saturdayClosingTime &&
                                formatTime(time) ===
                                  formatTime(saturdayClosingTime);
                              const isSameAsStoredClosing =
                                time &&
                                selectedStore?.saturdayCloseTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.saturdayCloseTime
                                    )
                                  );

                              if (isSameAsClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: true,
                                  saturdayNewEqualStored: false,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: false,
                                  saturdayNewEqualStored: false,
                                }));
                              }

                              if (isSameAsStoredClosing) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: false,
                                  saturdayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: false,
                                  saturdayNewEqualStored: false,
                                }));
                              }
                            }}
                          />

                          <Typography className="mt-4" variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.saturdayEqual ||
                              errors.saturdayNewEqualStored
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Edit Close Time"
                            value={getFormattedTime(
                              selectedStore?.saturdayCloseTime
                            )}
                            onChange={(time) => {
                              setSaturdayClosingTime(time);
                              const isSameAsOpening =
                                saturdayOpeningTime &&
                                time &&
                                formatTime(saturdayOpeningTime) ===
                                  formatTime(time);
                              const isSameAsStoredOpening =
                                time &&
                                selectedStore?.saturdayStrtTime &&
                                formatTime(time) ===
                                  formatTime(
                                    getFormattedTime(
                                      selectedStore.saturdayStrtTime
                                    )
                                  );

                              if (isSameAsOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: true,
                                  saturdayNewEqualStored: false,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: false,
                                  saturdayNewEqualStored: false,
                                }));
                              }

                              if (isSameAsStoredOpening) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: false,
                                  saturdayNewEqualStored: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEqual: false,
                                  saturdayNewEqualStored: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}

                    {/* Error messages */}
                    {saturdayTimeError && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be EMPTY at this time.
                      </Typography>
                    )}
                    {saturdaySameTime && (
                      <Typography className="text-red-500" variant="caption">
                        Opening and closing times cannot be the same.
                      </Typography>
                    )}
                    {errors.saturdayNewEqualStored && (
                      <Typography className="text-red-500" variant="caption">
                        New opening time cannot be the same as the old closing
                        time.
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              {/* end of saturday  code ===> */}
              <Button
                onClick={finishSecondStep}
                disabled={isError}
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                className="w-[100%] py-2 mb-[25px]  rounded-full cursor-pointer"
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* end of step 2 option==<> */}

        {step3 === true && (
          <div className="   w-full sm:w-[98%] md:w-[98%] lg:w-[98%] xl:w-[90%] 2xl:w-[90%] mb-11 p-4 mx-auto ">
            <div className="text-poppins text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl  mb-3 text-start">
              Add Photos
            </div>
            <div className="w-full flex flex-col justify-center ">
              <label className="-mb-[10px] text-gray-600">
                Store - front Photo
                <span className="text-red-500">*</span>
              </label>
              <br />
              <MuiFileInput
                className="mb-[10px] w-[98%] sm:w-[98%]"
                placeholder="Upload image"
                InputProps={{
                  inputProps: {
                    accept: "image/*",
                  },
                  startAdornment: <ImageIcon />,
                }}
                value={storeImage}
                onChange={(e) => handleStoreImage(e)}
              />
              <br />
              <label className="-mb-[10px] text-gray-600 w-full sm:w-[98%] ">
                Store - front Photo
                <span className="text-red-500">*</span>
              </label>
              <br />
              <MuiFileInput
                className="mb-[10px]  w-[98%] sm:w-[98%]"
                placeholder="Upload Image"
                InputProps={{
                  inputProps: {
                    accept: "image/*",
                  },
                  startAdornment: <ImageIcon />,
                }}
                value={areaImage}
                onChange={(e) => handleAreImage(e)}
              />
              <br />
              <Button
                onClick={finishThirdStep}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  marginTop: "10px",
                }}
                className="bg-black text-white w-[98%] py-2 mb-16 mt-16 rounded-full cursor-pointer"
                // disabled={!storeImage || !areaImage}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* end of step3 */}

        {step4 === true && (
          <div className=" flex flex-col justify-center p-3 w-full sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 mx-auto mt-[75px]">
            <h3 className="font-dm-sans text-center">Terms & Conditions</h3>
            <p className="text-center text-[16px]">
              By proceeding you agree with Doorap’s Partner{" "}
              <a
                className="text-black font-bold"
                href="https://doorap.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>{" "}
              and accept our{" "}
              <a
                className="text-black font-bold"
                href="https://doorap.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <br />
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              style={{
                backgroundColor: "black",
                color: "white",
                marginTop: "10px",
              }}
              className="bg-black text-white w-[98%] py-2 mb-16 mt-16 rounded-full cursor-pointer"
            >
              Accept & continue
            </Button>
            {isLoading && (
              <div className="mx-auto mt-4 flex">
                <CircularProgress />
              </div>
            )}
            {error && (
              <Alert
                className="w-full sm:w-1/2 mt-4 mx-auto"
                severity="warning"
              >
                store create fail
              </Alert>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default StoreList;
