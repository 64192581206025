import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet, privatePost } from "../../utils";

export const makeWithrow = createAsyncThunk(
  "payment",
  async ({ data, vendorId, token }, { rejectWithValue }) => {
    try {
      const response = await privatePost(
        `/payment/withrow/${vendorId}`,
        token,
        data
      );
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const transectionHistory = createAsyncThunk(
  "tnx history",
  async ({ vendorId, token }, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/payment/tnx/${vendorId}`, token);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const paymentSlice = createSlice({
  name: "Payment",
  initialState: {
    isLoading: false,
    error: null,
    withrowSuccess: false,
    tnxHistory: [],
  },
  extraReducers: (builder) => {
    builder.addCase(makeWithrow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(makeWithrow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.withrowSuccess = true;
      state.error = null;
    });
    builder.addCase(makeWithrow.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.response;
      state.withrowSuccess = false;
    });
    builder.addCase(transectionHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(transectionHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tnxHistory = action.payload;
      state.error = null;
    });
    builder.addCase(transectionHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.response;
      state.tnxHistory = [];
    });
  },
});
export default paymentSlice.reducer;
