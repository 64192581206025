import React from "react";
import Layout from "../components/layout";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
const AskToCreateStore = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/create/new/store");
  };
  return (
    <Layout>
      <h3
        style={{
          marginTop: "70px",
          fontFamily: "Poppins",
          textAlign: "left",
          marginLeft: "2%",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        Create Store
      </h3>
      <div style={{ marginLeft: "2%" }}>
        <Typography style={{ marginBottom: "20px", fontFamily: "Poppins" }}>
          Creating a new store is simple and can be done in just a few clicks. <br></br>
          Click on the button below to get started and take the next step in
          growing your business empire.
        </Typography>
        <Button
          onClick={handleRedirect}
          style={{
            backgroundColor: "black",
            padding: "10px",
            textDecoration: "none",
            color: "white",
            fontFamily: "Poppins",
            fontWeight:"bold"
            
          }}
        >
          Create Store{" "}
          <StorefrontOutlinedIcon
            style={{ marginLeft: "5px", color: "white", height: "20px" }}
          />
        </Button>
      </div>
    </Layout>
  );
};

export default AskToCreateStore;
