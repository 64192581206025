/** @format */
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  Switch,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ImageIcon from "@mui/icons-material/Image";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import {
  createLuggage,
  getLuggageByProvider,
} from "../state/luggage/luggageSlice";
import { MuiFileInput } from "mui-file-input";
import formatTimeByHourAndMinute from "../utils/formatTime";
import Layout from "../components/layout";
import "@fontsource/poppins";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
var countries = require("countries-cities").getCountries();
var cityList = require("countries-cities");

const CreateAnotherStore = () => {
  const userReducer = useSelector((state) => state.userData);
  const provider = userReducer.loginUser;
  const { error, isLoading } = useSelector((state) => state.luggageData);
  const navigate = useNavigate();
  const { token, data } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [storeName, setStoreName] = useState(null);

  const [providerName, setProviderName] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [providerMobile, setProviderMobile] = useState("");
  const [providerID, setProviderID] = useState("");
  const [cityName, setCityName] = useState("Paris");
  const [countryName, setCountryName] = useState("France");
  const cityArr = cityList.getCities(countryName);
  const [mapLat, setMapLat] = useState("");
  const [mapLan, setMapLan] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [bussinessType, setBussinessType] = useState(null);
  // ====>
  const [sundayOpeningTime, setSundayOpeningTime] = useState(null);
  console.log("sunday opining time===>", sundayOpeningTime);

  const [sundayClosingTime, setSundayClosingTime] = useState(null);
  const [mondayOpeningTime, setMondayOpeningTime] = useState(null);
  const [mondayClosingTime, setMondayClosingTime] = useState(null);
  const [tuesdayOpeningTime, setTuesdayOpeningTime] = useState(null);
  const [tuesdayClosingTime, setTuesdayClosingTime] = useState(null);
  const [wednesdayOpeningTime, setWednesdayOpeningTime] = useState(null);
  const [wednesdayClosingTime, setWednesdayClosingTime] = useState(null);
  const [thursdayOpeningTime, setThursdayOpeningTime] = useState(null);
  const [thursdayClosingTime, setThursdayClosingTime] = useState(null);
  const [fridayOpeningTime, setFridayOpeningTime] = useState(null);
  const [fridayClosingTime, setFridayClosingTime] = useState(null);
  const [saturdayOpeningTime, setSaturdayOpeningTime] = useState(null);
  const [saturdayClosingTime, setSaturdayClosingTime] = useState(null);
  const [location, setLocation] = useState(null);
  //======>
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  //=======>
  const [storeImage, setStoreImage] = useState(null);
  const [compreseStoreImage, setCompereseStoreImage] = useState(null);
  const [areaImage, setAreaImage] = useState(null);
  const [copmresAreaImage, setCompreseAreaImage] = useState(null);
  const [sundayStatus, setSundayStatus] = useState(true);
  const [mondayStatus, setMondayStatus] = useState(true);
  const [tuesdayStatus, setTuesdayStatus] = useState(true);
  const [wednesdayStatus, setWednesdayStatus] = useState(true);
  const [thursdayStatus, setThursdayStatus] = useState(true);
  const [fridayStatus, setFridayStatus] = useState(true);
  const [saturdayStatus, setSaturdayStatus] = useState(true);

  const [sundayFull, setSundayFull] = useState(false);
  const [mondayFull, setMondayFull] = useState(false);
  const [tuesdayFull, setTuesdayFull] = useState(false);
  const [wednesdayFull, setWednesdayFull] = useState(false);
  const [thursdayFull, setThursdayFull] = useState(false);
  const [fridayFull, setFridayFull] = useState(false);
  const [saturdayFull, setSaturdayFull] = useState(false);

  const handleSundayStatus = () => {
    if (sundayStatus === false) {
      return setSundayStatus(true);
    }
    return setSundayStatus(false);
  };
  const handleMondayStatus = () => {
    if (mondayStatus === true) {
      return setMondayStatus(false);
    }
    return setMondayStatus(true);
  };
  const handleTuesdayStatus = () => {
    if (tuesdayStatus === false) {
      return setTuesdayStatus(true);
    }
    return setTuesdayStatus(false);
  };
  const handleWednesdayStatus = () => {
    if (wednesdayStatus === false) {
      return setWednesdayStatus(true);
    }
    return setWednesdayStatus(false);
  };
  const handleThursdayStatus = () => {
    if (thursdayStatus === false) {
      return setThursdayStatus(true);
    }
    return setThursdayStatus(false);
  };
  const handleFridayStatus = () => {
    if (fridayStatus === false) {
      return setFridayStatus(true);
    }
    return setFridayStatus(false);
  };
  const handleSaturdayStatus = () => {
    if (saturdayStatus === false) {
      return setSaturdayStatus(true);
    }
    return setSaturdayStatus(false);
  };

  const handleSundayFull = () => {
    if (sundayFull === false) {
      return setSundayFull(true);
    }
    return setSundayFull(false);
  };
  const handleMondayFull = () => {
    if (mondayFull === false) {
      return setMondayFull(true);
    }
    return setMondayFull(false);
  };
  const handleTuesdayFull = () => {
    if (tuesdayFull === false) {
      return setTuesdayFull(true);
    }
    return setTuesdayFull(false);
  };
  const handleWednesdayFull = () => {
    if (wednesdayFull === false) {
      return setWednesdayFull(true);
    }
    return setWednesdayFull(false);
  };
  const handleThursdayFull = () => {
    if (thursdayFull === false) {
      return setThursdayFull(true);
    }
    return setThursdayFull(false);
  };
  const handleFridayFull = () => {
    if (fridayFull === false) {
      return setFridayFull(true);
    }
    return setFridayFull(false);
  };
  const handleSaturdayFull = () => {
    if (saturdayFull === false) {
      return setSaturdayFull(true);
    }
    return setSaturdayFull(false);
  };

  const finishFirstStep = () => {
    setStep1(false);
    setStep2(true);
  };

  const [errors, setErrors] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });
  // =============>

  const finishSecondStep = () => {
    const newErrors = {
      sundayEmpty:
        sundayStatus &&
        !sundayFull &&
        (!sundayOpeningTime || !sundayClosingTime),
      sundayEqual:
        sundayStatus &&
        !sundayFull &&
        sundayOpeningTime &&
        sundayClosingTime &&
        sundayOpeningTime.$H === sundayClosingTime.$H &&
        sundayOpeningTime.$m === sundayClosingTime.$m,
      mondayEmpty:
        mondayStatus &&
        !mondayFull &&
        (!mondayOpeningTime || !mondayClosingTime),
      mondayEqual:
        mondayStatus &&
        !mondayFull &&
        mondayOpeningTime &&
        mondayClosingTime &&
        mondayOpeningTime.$H === mondayClosingTime.$H &&
        mondayOpeningTime.$m === mondayClosingTime.$m,
      tuesdayEmpty:
        tuesdayStatus &&
        !tuesdayFull &&
        (!tuesdayOpeningTime || !tuesdayClosingTime),
      tuesdayEqual:
        tuesdayStatus &&
        !tuesdayFull &&
        tuesdayOpeningTime &&
        tuesdayClosingTime &&
        tuesdayOpeningTime.$H === tuesdayClosingTime.$H &&
        tuesdayOpeningTime.$m === tuesdayClosingTime.$m,
      wednesdayEmpty:
        wednesdayStatus &&
        !wednesdayFull &&
        (!wednesdayOpeningTime || !wednesdayClosingTime),
      wednesdayEqual:
        wednesdayStatus &&
        !wednesdayFull &&
        wednesdayOpeningTime &&
        wednesdayClosingTime &&
        wednesdayOpeningTime.$H === wednesdayClosingTime.$H &&
        wednesdayOpeningTime.$m === wednesdayClosingTime.$m,
      thursdayEmpty:
        thursdayStatus &&
        !thursdayFull &&
        (!thursdayOpeningTime || !thursdayClosingTime),
      thursdayEqual:
        thursdayStatus &&
        !thursdayFull &&
        thursdayOpeningTime &&
        thursdayClosingTime &&
        thursdayOpeningTime.$H === thursdayClosingTime.$H &&
        thursdayOpeningTime.$m === thursdayClosingTime.$m,
      fridayEmpty:
        fridayStatus &&
        !fridayFull &&
        (!fridayOpeningTime || !fridayClosingTime),
      fridayEqual:
        fridayStatus &&
        !fridayFull &&
        fridayOpeningTime &&
        fridayClosingTime &&
        fridayOpeningTime.$H === fridayClosingTime.$H &&
        fridayOpeningTime.$m === fridayClosingTime.$m,
      saturdayEmpty:
        saturdayStatus &&
        !saturdayFull &&
        (!saturdayOpeningTime || !saturdayClosingTime),
      saturdayEqual:
        saturdayStatus &&
        !saturdayFull &&
        saturdayOpeningTime &&
        saturdayClosingTime &&
        saturdayOpeningTime.$H === saturdayClosingTime.$H &&
        saturdayOpeningTime.$m === saturdayClosingTime.$m,
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some((error) => error);

    if (!hasError) {
      setStep2(false);
      setStep3(true);
    }
  };

  const finishThirdStep = () => {
    setStep3(false);
    setStep4(true);
  };

  const handleStoreImage = async (e) => {
    setStoreImage(e);
    const imageFile = e;
    if (!imageFile) return;
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      setCompereseStoreImage(compressedFile);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  const handleAreImage = async (e) => {
    setAreaImage(e);
    const imageFile = e;
    if (!imageFile) return;
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      setCompreseAreaImage(compressedFile);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  useEffect(() => {
    if (provider) {
      setProviderName(provider.name);
      setProviderEmail(provider.email);
      setProviderID(provider.id);
      setProviderMobile(provider.mobile);
    }
  }, [provider]);
  const inputRef = useRef();

  const handlePaceChange = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setMapLan(place.geometry.location.lng());
      setMapLat(place.geometry.location.lat());
      setLocation(place.formatted_address);
    }
  };
  const handleSubmit = async () => {
    const sundayStrtTime = await formatTimeByHourAndMinute(
      sundayOpeningTime?.$H,
      sundayOpeningTime?.$m
    );
    const sundayCloseTime = await formatTimeByHourAndMinute(
      sundayClosingTime?.$H,
      sundayClosingTime?.$m
    );
    const mondayStrtTime = await formatTimeByHourAndMinute(
      mondayOpeningTime?.$H,
      mondayOpeningTime?.$m
    );
    const mondayCloseTime = await formatTimeByHourAndMinute(
      mondayClosingTime?.$H,
      mondayClosingTime?.$m
    );
    const tuesdayStrtTime = await formatTimeByHourAndMinute(
      tuesdayOpeningTime?.$H,
      tuesdayOpeningTime?.$m
    );
    const tuesdayCloseTime = await formatTimeByHourAndMinute(
      tuesdayClosingTime?.$H,
      tuesdayClosingTime?.$m
    );
    const wednesdayStrtTime = await formatTimeByHourAndMinute(
      wednesdayOpeningTime?.$H,
      wednesdayOpeningTime?.$m
    );
    const wednesdayCloseTime = await formatTimeByHourAndMinute(
      wednesdayClosingTime?.$H,
      wednesdayClosingTime?.$m
    );
    const thursdayStrtTime = await formatTimeByHourAndMinute(
      thursdayOpeningTime?.$H,
      thursdayOpeningTime?.$m
    );
    const thursdayCloseTime = await formatTimeByHourAndMinute(
      thursdayClosingTime?.$H,
      thursdayClosingTime?.$m
    );
    const fridayStrtTime = await formatTimeByHourAndMinute(
      fridayOpeningTime?.$H,
      fridayOpeningTime?.$m
    );
    const fridayCloseTime = await formatTimeByHourAndMinute(
      fridayClosingTime?.$H,
      fridayClosingTime?.$m
    );
    const saturdayStrtTime = await formatTimeByHourAndMinute(
      saturdayOpeningTime?.$H,
      saturdayOpeningTime?.$m
    );
    const saturdayCloseTime = await formatTimeByHourAndMinute(
      saturdayClosingTime?.$H,
      saturdayClosingTime?.$m
    );

    const formDataToSend = new FormData();
    formDataToSend.append("businessName", storeName);
    formDataToSend.append("providerName", providerName);
    formDataToSend.append("providerEmail", providerEmail);
    formDataToSend.append("providerID", providerID);
    formDataToSend.append("providerMobile", providerMobile);
    formDataToSend.append("countryName", countryName);
    formDataToSend.append("cityName", cityName);
    formDataToSend.append("mapLat", mapLat);
    formDataToSend.append("mapLan", mapLan);
    formDataToSend.append("address", location);
    formDataToSend.append("zipCode", zipCode);
    formDataToSend.append("sundayStrtTime", sundayStrtTime);
    formDataToSend.append("sundayCloseTime", sundayCloseTime);
    formDataToSend.append("mondayStrtTime", mondayStrtTime);
    formDataToSend.append("mondayCloseTime", mondayCloseTime);
    formDataToSend.append("tuesdayStrtTime", tuesdayStrtTime);
    formDataToSend.append("tuesdayCloseTime", tuesdayCloseTime);
    formDataToSend.append("wednesdayStrtTime", wednesdayStrtTime);
    formDataToSend.append("wednesdayCloseTime", wednesdayCloseTime);
    formDataToSend.append("thursdayStrtTime", thursdayStrtTime);
    formDataToSend.append("thursdayCloseTime", thursdayCloseTime);
    formDataToSend.append("fridayStrtTime", fridayStrtTime);
    formDataToSend.append("fridayCloseTime", fridayCloseTime);
    formDataToSend.append("saturdayStrtTime", saturdayStrtTime);
    formDataToSend.append("saturdayCloseTime", saturdayCloseTime);
    formDataToSend.append("bussinessType", bussinessType);
    formDataToSend.append("storeImage", compreseStoreImage);
    formDataToSend.append("areaImage", copmresAreaImage);
    formDataToSend.append("sundayFull", sundayFull);
    formDataToSend.append("mondayFull", mondayFull);
    formDataToSend.append("tuesdayFull", tuesdayFull);
    formDataToSend.append("wednesdayFull", wednesdayFull);
    formDataToSend.append("thursdayFull", thursdayFull);
    formDataToSend.append("fridayFull", fridayFull);
    formDataToSend.append("saturdayFull", saturdayFull);
    formDataToSend.append("sundayStatus", sundayStatus);
    formDataToSend.append("mondayStatus", mondayStatus);
    formDataToSend.append("tuesdayStatus", tuesdayStatus);
    formDataToSend.append("wednesdayStatus", wednesdayStatus);
    formDataToSend.append("thursdayStatus", thursdayStatus);
    formDataToSend.append("fridayStatus", fridayStatus);
    formDataToSend.append("saturdayStatus", saturdayStatus);
    await dispatch(createLuggage({ token, data: formDataToSend }));
    await dispatch(getLuggageByProvider({ token, providerId: data?.id }));
    navigate("/");
  };

  const checkFieldCompletion = (field) => {
    return !field;
  };
  console.log("=============test=======================");
  console.log(
    storeName,
    bussinessType,
    zipCode,
    location,
    cityName,
    countryName
  );
  console.log("====================================");

  let dis = false;
  if (
    !storeName ||
    storeName === null ||
    countryName === null ||
    cityName === null ||
    !location ||
    location === null ||
    !zipCode ||
    zipCode === null ||
    !bussinessType ||
    bussinessType === null
  ) {
    dis = true;
  }

  // end of disable function====>

  return (
    <Layout>
      <div className=" flex flex-col justify-center">
        {step1 === true && (
          <div className="  flex justify-center  p-[3px]  w-full sm:w-[98%] md:w-[95%] lg:w-[90%] xl:w-[85%] 2xl:w-[85%] mx-auto">
            <div className="w-full mt-[50px] ">
              <div className="font-semibold text-black text-xl max-sm:text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl">
                Business Information
              </div>
              <div className="-mb-[10px] mt-[20px] ">
                <label
                  style={{
                    color: "#666666",
                    marginLeft: "5px",
                    borderColor: checkFieldCompletion(storeName) ? "red" : " ",
                  }}
                >
                  Business Name
                </label>{" "}
                {checkFieldCompletion(storeName) && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Is Required
                  </Typography>
                )}
              </div>
              <br />
              <div className="-mb-[10px] -mt-[5px]  ">
                <TextField
                  className="w-[98%] p-1.5 mb-[20px] "
                  id="storeName"
                  placeholder="Joe"
                  variant="outlined"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  required
                />{" "}
              </div>
              <br />
              <div className="-mb-[10px] ">
                <label className="text-gray-600 ml-1 mt-2">Country</label>{" "}
                {!checkFieldCompletion(countryName) && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Is Required
                  </Typography>
                )}
              </div>
              <br />
              <div className="-mb-[10px] -mt-[5px]  ">
                <TextField
                  className={`w-[98%] p-[5px] mb-2 mt-1 ${
                    checkFieldCompletion(storeName)
                      ? "border border-red-500"
                      : ""
                  }`}
                  id="fkCountry"
                  name="fkCountry"
                  select
                  value={countryName}
                  onChange={(e) => setCountryName(e.target.value)}
                  variant="outlined"
                  required
                >
                  {countries?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>{" "}
              </div>
              <br />
              <div className="-mb-[10px] ">
                <label className="text-gray-600 ml-1 mt-2">City</label>{" "}
                {!checkFieldCompletion(cityName) && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Is Required
                  </Typography>
                )}
              </div>
              <br />

              <div className="-mb-[10px] -mt-[5px] ">
                <TextField
                  className={`w-[98%] p-[5px] mb-5 mt-1 ${
                    checkFieldCompletion(storeName)
                      ? "border border-red-500"
                      : ""
                  }`}
                  id="fkCity"
                  name="fkCity"
                  select
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                  variant="outlined"
                  required
                >
                  {cityArr?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>{" "}
              </div>
              <br />
              <div className="-mb-[10px] ">
                <label className="text-gray-600 ml-1 mt-2">Address</label>{" "}
                {checkFieldCompletion(location) && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Is Required
                  </Typography>
                )}
              </div>
              <br />
              <div className="-mb-[10px] -mt-[5px]  ">
                <LoadScript
                  googleMapsApiKey="AIzaSyBxgJmS9RNOnpiasONTvGUGgS7GRGsH7cg"
                  libraries={["places"]}
                >
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={handlePaceChange}
                  >
                    <input
                      className="w-[98%] p-[2px] mb-4 mt-1  rounded-[5px] h-[55px] border border-gray-300"
                      type="text"
                      // className="form-control"
                      placeholder="House - 02, Street - 04, Avenue - B, Banasree"
                      required
                    />
                  </StandaloneSearchBox>
                </LoadScript>{" "}
              </div>
              {/* end of address */}
              <div className="-mb-[10px] ">
                <label className="text-gray-600 ml-1 -mt-2">Postcode </label>
                {checkFieldCompletion(zipCode) && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Is Required
                  </Typography>
                )}
              </div>
              <br />
              <div className="-mb-[10px] -mt-[5px]  ">
                <TextField
                  className={`w-[98%] p-[5px] ${
                    checkFieldCompletion(storeName)
                      ? "border border-red-500"
                      : ""
                  }`}
                  id="zipCode"
                  placeholder="2323"
                  variant="outlined"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
              </div>
              <br />
              <div className="-mb-[10px] ">
                <label
                  style={{
                    color: "#666666",
                    marginLeft: "5px",

                    borderColor: checkFieldCompletion(bussinessType)
                      ? "red"
                      : " ",
                  }}
                >
                  Business Type
                </label>{" "}
                {checkFieldCompletion(bussinessType) && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Is Required
                  </Typography>
                )}
              </div>
              <br />
              <div className="-mb-[10px] -mt-[5px]  ">
                <TextField
                  className="w-[98%] p-[5px] "
                  id="storeName"
                  placeholder="Joe"
                  variant="outlined"
                  value={bussinessType}
                  onChange={(e) => setBussinessType(e.target.value)}
                  required
                />{" "}
              </div>
              <Button
                onClick={finishFirstStep}
                disabled={dis}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  marginTop: "30px",
                  marginBottom: "90px",
                }}
                className="w-[98%] py-2 mt-4 rounded-full cursor-pointer"
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {/* end of first step ==>>> */}

        {step2 === true && (
          <div className=" w-full sm:w-[98%] md:w-[98%] lg:w-[98%] xl:w-[90%] 2xl:w-[90%] mb-[20px]   mx-auto ">
            <div className="w-full justify-center items-center  ">
              <h3 className=" mb-6 mt-5  sm:mt-6 md:mt-5 lg:mt-[10px] xl:mt-20 2xl:mt-24 text-xl sm:text-[12px] md:text-md lg:text-2xl xl:text-2xl 2xl:text-3xl  font-semibold text-black text-left">
                Business Hour
              </h3>

              <div className="flex  ">
                <div className="w-[170px] flex  gap-[50px] ">
                  <FormControlLabel
                    onClick={handleSundayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Sunday"
                  />
                </div>
                <div>
                  {sundayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleSundayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>

              {!sundayFull && sundayStatus && (
                <div className="">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker", "TimePicker"]}>
                      <TimePicker
                        className={`flex justify-center w-[98%] p-1 mb-2 ${
                          errors.sundayEmpty || errors.sundayEqual
                            ? "border border-red-500"
                            : ""
                        }`}
                        label="Set Start Time"
                        value={sundayOpeningTime}
                        onChange={(time) => {
                          setSundayOpeningTime(time);
                          if (!time || !sundayClosingTime) {
                            setErrors((prev) => ({
                              ...prev,
                              sundayEmpty: true,
                              sundayEqual: false,
                            }));
                          } else if (
                            time.$H === sundayClosingTime.$H &&
                            time.$m === sundayClosingTime.$m
                          ) {
                            setErrors((prev) => ({
                              ...prev,
                              sundayEmpty: false,
                              sundayEqual: true,
                            }));
                          } else {
                            setErrors((prev) => ({
                              ...prev,
                              sundayEmpty: false,
                              sundayEqual: false,
                            }));
                          }
                        }}
                      />
                      <Typography style={{ marginTop: "5px" }} variant="p">
                        TO
                      </Typography>
                      <TimePicker
                        className={`flex justify-center w-[98%] p-1 mb-2 ${
                          errors.sundayEmpty || errors.sundayEqual
                            ? "border border-red-500"
                            : ""
                        }`}
                        label="Set Close Time"
                        value={sundayClosingTime}
                        onChange={(time) => {
                          setSundayClosingTime(time);
                          if (!sundayOpeningTime || !time) {
                            setErrors((prev) => ({
                              ...prev,
                              sundayEmpty: true,
                              sundayEqual: false,
                            }));
                          } else if (
                            time.$H === sundayOpeningTime.$H &&
                            time.$m === sundayOpeningTime.$m
                          ) {
                            setErrors((prev) => ({
                              ...prev,
                              sundayEmpty: false,
                              sundayEqual: true,
                            }));
                          } else {
                            setErrors((prev) => ({
                              ...prev,
                              sundayEmpty: false,
                              sundayEqual: false,
                            }));
                          }
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* Error messages */}
                  {errors.sundayEmpty && (
                    <Typography className="text-red-500" variant="caption">
                      Please fill in both start and end time for Sunday.
                    </Typography>
                  )}
                  {errors.sundayEqual && (
                    <Typography className="text-red-500" variant="caption">
                      Opening and closing times cannot be the same.
                    </Typography>
                  )}
                </div>
              )}
              {/* end of monday====> */}

              <div className="flex">
                <div className="w-[170px] flex gap-[50px] ">
                  <FormControlLabel
                    onClick={handleMondayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Monday"
                  />
                </div>

                <div>
                  {mondayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleMondayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>
              {mondayStatus === true && (
                <>
                  {mondayFull === false && (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.mondayEmpty || errors.mondayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Start Time"
                            value={mondayOpeningTime}
                            onChange={(time) => {
                              setMondayOpeningTime(time);
                              if (!time || !mondayClosingTime) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEmpty: true,
                                  mondayEqual: false,
                                }));
                              } else if (
                                time.$H === mondayClosingTime.$H &&
                                time.$m === mondayClosingTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEmpty: false,
                                  mondayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEmpty: false,
                                  mondayEqual: false,
                                }));
                              }
                            }}
                          />
                          <Typography style={{ marginTop: "10px" }} variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.mondayEmpty || errors.mondayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Close Time"
                            value={mondayClosingTime}
                            onChange={(time) => {
                              setMondayClosingTime(time);
                              if (!mondayOpeningTime || !time) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEmpty: true,
                                  mondayEqual: false,
                                }));
                              } else if (
                                time.$H === mondayOpeningTime.$H &&
                                time.$m === mondayOpeningTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEmpty: false,
                                  mondayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  mondayEmpty: false,
                                  mondayEqual: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Error messages */}
                      {errors.mondayEmpty && (
                        <Typography className="text-red-500" variant="caption">
                          Please fill in both start and end time for Monday.
                        </Typography>
                      )}
                      {errors.mondayEqual && (
                        <Typography className="text-red-500" variant="caption">
                          Opening and closing times cannot be the same.
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}
              <div className="flex">
                <div className="w-[170px] flex gap-[50px] ">
                  <FormControlLabel
                    onClick={handleTuesdayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Tuesday"
                  />
                </div>

                <div>
                  {tuesdayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleTuesdayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>
              {tuesdayStatus === true && (
                <>
                  {tuesdayFull === false && (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.tuesdayEmpty || errors.tuesdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Start Time"
                            value={tuesdayOpeningTime}
                            onChange={(time) => {
                              setTuesdayOpeningTime(time);
                              if (!time || !tuesdayClosingTime) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEmpty: true,
                                  tuesdayEqual: false,
                                }));
                              } else if (
                                time.$H === tuesdayClosingTime.$H &&
                                time.$m === tuesdayClosingTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEmpty: false,
                                  tuesdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEmpty: false,
                                  tuesdayEqual: false,
                                }));
                              }
                            }}
                          />
                          <Typography style={{ marginTop: "10px" }} variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.tuesdayEmpty || errors.tuesdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Close Time"
                            value={tuesdayClosingTime}
                            onChange={(time) => {
                              setTuesdayClosingTime(time);
                              if (!tuesdayOpeningTime || !time) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEmpty: true,
                                  tuesdayEqual: false,
                                }));
                              } else if (
                                time.$H === tuesdayOpeningTime.$H &&
                                time.$m === tuesdayOpeningTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEmpty: false,
                                  tuesdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  tuesdayEmpty: false,
                                  tuesdayEqual: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Error message */}
                      {errors.tuesdayEmpty && (
                        <Typography className="text-red-500" variant="caption">
                          Please fill in both start and end time for Tuesday.
                        </Typography>
                      )}
                      {errors.tuesdayEqual && (
                        <Typography className="text-red-500" variant="caption">
                          Opening and closing times cannot be the same.
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}
              <div className="flex">
                <div className="w-[170px] flex gap-[50px] ">
                  <FormControlLabel
                    onClick={handleWednesdayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Wednesday"
                  />
                </div>

                <div>
                  {wednesdayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleWednesdayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>
              {wednesdayStatus === true && (
                <>
                  {wednesdayFull === false && (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.wednesdayEmpty || errors.wednesdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Start Time "
                            value={wednesdayOpeningTime}
                            onChange={(time) => {
                              setWednesdayOpeningTime(time);
                              if (!time || !wednesdayClosingTime) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEmpty: true,
                                  wednesdayEqual: false,
                                }));
                              } else if (
                                time.$H === wednesdayClosingTime.$H &&
                                time.$m === wednesdayClosingTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEmpty: false,
                                  wednesdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEmpty: false,
                                  wednesdayEqual: false,
                                }));
                              }
                            }}
                          />
                          <Typography style={{ marginTop: "10px" }} variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.wednesdayEmpty || errors.wednesdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Close Time "
                            value={wednesdayClosingTime}
                            onChange={(time) => {
                              setWednesdayClosingTime(time);
                              if (!wednesdayOpeningTime || !time) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEmpty: true,
                                  wednesdayEqual: false,
                                }));
                              } else if (
                                time.$H === wednesdayOpeningTime.$H &&
                                time.$m === wednesdayOpeningTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEmpty: false,
                                  wednesdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  wednesdayEmpty: false,
                                  wednesdayEqual: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Error message */}
                      {errors.wednesdayEmpty && (
                        <Typography className="text-red-500" variant="caption">
                          Please fill in both start and end time for Wednesday.
                        </Typography>
                      )}
                      {errors.wednesdayEqual && (
                        <Typography className="text-red-500" variant="caption">
                          Opening and closing times cannot be the same.
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className="flex">
                <div className="w-[170px] flex gap-[50px] ">
                  <FormControlLabel
                    onClick={handleThursdayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Thursday"
                  />
                </div>
                <div>
                  {thursdayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleThursdayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>

              {thursdayStatus === true && (
                <>
                  {thursdayFull === false && (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.thursdayEmpty || errors.thursdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Start Time "
                            value={thursdayOpeningTime}
                            onChange={(time) => {
                              setThursdayOpeningTime(time);
                              if (!time || !thursdayClosingTime) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEmpty: true,
                                  thursdayEqual: false,
                                }));
                              } else if (
                                time.$H === thursdayClosingTime.$H &&
                                time.$m === thursdayClosingTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEmpty: false,
                                  thursdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEmpty: false,
                                  thursdayEqual: false,
                                }));
                              }
                            }}
                          />
                          <Typography style={{ marginTop: "10px" }} variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.thursdayEmpty || errors.thursdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Close Time "
                            value={thursdayClosingTime}
                            onChange={(time) => {
                              setThursdayClosingTime(time);
                              if (!thursdayOpeningTime || !time) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEmpty: true,
                                  thursdayEqual: false,
                                }));
                              } else if (
                                time.$H === thursdayOpeningTime.$H &&
                                time.$m === thursdayOpeningTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEmpty: false,
                                  thursdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  thursdayEmpty: false,
                                  thursdayEqual: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Error message */}
                      {errors.thursdayEmpty && (
                        <Typography className="text-red-500" variant="caption">
                          Please fill in both start and end time for Thursday.
                        </Typography>
                      )}
                      {errors.thursdayEqual && (
                        <Typography className="text-red-500" variant="caption">
                          Opening and closing times cannot be the same.
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className="flex">
                <div className="w-[170px] flex gap-[50px] ">
                  <FormControlLabel
                    onClick={handleFridayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Friday"
                  />
                </div>

                <div className="">
                  {fridayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleFridayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>
              {fridayStatus === true && (
                <>
                  {fridayFull === false && (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.fridayEmpty || errors.fridayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Start Time"
                            value={fridayOpeningTime}
                            onChange={(time) => {
                              setFridayOpeningTime(time);
                              if (!time || !fridayClosingTime) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEmpty: true,
                                  fridayEqual: false,
                                }));
                              } else if (
                                time.$H === fridayClosingTime.$H &&
                                time.$m === fridayClosingTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEmpty: false,
                                  fridayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEmpty: false,
                                  fridayEqual: false,
                                }));
                              }
                            }}
                          />
                          <Typography style={{ marginTop: "10px" }} variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.fridayEmpty || errors.fridayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Close Time"
                            value={fridayClosingTime}
                            onChange={(time) => {
                              setFridayClosingTime(time);
                              if (!fridayOpeningTime || !time) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEmpty: true,
                                  fridayEqual: false,
                                }));
                              } else if (
                                time.$H === fridayOpeningTime.$H &&
                                time.$m === fridayOpeningTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEmpty: false,
                                  fridayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  fridayEmpty: false,
                                  fridayEqual: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Error message */}
                      {errors.fridayEmpty && (
                        <Typography className="text-red-500" variant="caption">
                          Please fill in both start and end time for Friday.
                        </Typography>
                      )}
                      {errors.fridayEqual && (
                        <Typography className="text-red-500" variant="caption">
                          Opening and closing times cannot be the same.
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}
              {/* end of friday ---> */}

              <div className="flex">
                <div className="w-[170px] flex gap-[50px] ">
                  <FormControlLabel
                    onClick={handleSaturdayStatus}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    label="Saturday"
                  />
                </div>

                <div>
                  {saturdayStatus === true && (
                    <FormControlLabel
                      control={<Checkbox onChange={handleSaturdayFull} />}
                      label="24hr Open"
                    />
                  )}
                </div>
              </div>
              {saturdayStatus === true && (
                <>
                  {saturdayFull === false && (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["TimePicker", "TimePicker"]}
                        >
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.saturdayEmpty || errors.saturdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Start Time "
                            value={saturdayOpeningTime}
                            onChange={(time) => {
                              setSaturdayOpeningTime(time);
                              if (!time || !saturdayClosingTime) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEmpty: true,
                                  saturdayEqual: false,
                                }));
                              } else if (
                                time.$H === saturdayClosingTime.$H &&
                                time.$m === saturdayClosingTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEmpty: false,
                                  saturdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEmpty: false,
                                  saturdayEqual: false,
                                }));
                              }
                            }}
                          />
                          <Typography style={{ marginTop: "10px" }} variant="p">
                            TO
                          </Typography>
                          <TimePicker
                            className={`flex justify-center w-[98%] p-1 mb-2 ${
                              errors.saturdayEmpty || errors.saturdayEqual
                                ? "border border-red-500"
                                : ""
                            }`}
                            label="Set Close Time "
                            value={saturdayClosingTime}
                            onChange={(time) => {
                              setSaturdayClosingTime(time);
                              if (!saturdayOpeningTime || !time) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEmpty: true,
                                  saturdayEqual: false,
                                }));
                              } else if (
                                time.$H === saturdayOpeningTime.$H &&
                                time.$m === saturdayOpeningTime.$m
                              ) {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayCloseTimeEmpty: false,
                                  saturdayEqual: true,
                                }));
                              } else {
                                setErrors((prev) => ({
                                  ...prev,
                                  saturdayEmpty: false,
                                  saturdayEqual: false,
                                }));
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Error message */}
                      {errors.saturdayEmpty && (
                        <Typography className="text-red-500" variant="caption">
                          Please fill in both start and end time for Saturday.
                        </Typography>
                      )}
                      {errors.saturdayEqual && (
                        <Typography className="text-red-500" variant="caption">
                          Opening and closing times cannot be the same.
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}
              <div className="mt-[20px] ">
                <Button
                  onClick={finishSecondStep}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    marginBottom: "40px",
                  }}
                  className=" w-full  h-auto mb-8 sm:mt-10 max-sm:mt-10 md:mb-12 lg:mt-12 xl:mt-12 2xl:mt-12 rounded-lg cursor-pointer"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* end of step 2 ----> */}

        {step3 === true && (
          <>
            <div className="  w-full sm:w-[98%] md:w-[98%] lg:w-[98%] xl:w-[90%] 2xl:w-[90%] mb-11 p-4 mx-auto ">
              <div className=" mt-[50px] text-poppins text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl  mb-3 text-start">
                Add Photos
              </div>
              <div className="w-full flex flex-col justify-center ">
                <label className="-mb-[10px] text-gray-600">
                  Store - front Photo
                  <span className="text-red-500">*</span>
                </label>
                <br />
                <MuiFileInput
                  className="mb-[10px] w-[98%] sm:w-[98%]"
                  placeholder="Upload image"
                  InputProps={{
                    inputProps: {
                      accept: "image/*",
                    },
                    startAdornment: <ImageIcon />,
                  }}
                  value={storeImage}
                  onChange={(e) => handleStoreImage(e)}
                />
                <br />
                <label className="-mb-[10px] text-gray-600 w-full sm:w-[98%] ">
                  Store - front Photo
                  <span className="text-red-500">*</span>
                </label>
                <br />
                <MuiFileInput
                  className="mb-[10px]  w-[98%] sm:w-[98%]"
                  placeholder="Upload Image"
                  InputProps={{
                    inputProps: {
                      accept: "image/*",
                    },
                    startAdornment: <ImageIcon />,
                  }}
                  value={areaImage}
                  onChange={(e) => handleAreImage(e)}
                />
                <br />
                <Button
                  onClick={finishThirdStep}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    marginTop: "10px",
                  }}
                  className="bg-black text-white w-[98%] py-2 mb-16 mt-16 rounded-full cursor-pointer"
                  disabled={!storeImage || !areaImage}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )}

        {/* end of step 3 */}
        {step4 === true && (
          <div className=" flex flex-col justify-center p-3 w-full sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 mx-auto mt-[75px]">
            <h3 className="font-dm-sans text-center">Terms & Conditions</h3>
            <p className="text-center text-[16px]">
              By proceeding you agree with Doorap’s Partner{" "}
              <a
                className="text-black font-bold"
                href="https://doorap.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>{" "}
              and accept our{" "}
              <a
                className="text-black font-bold"
                href="https://doorap.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <br />
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              style={{
                backgroundColor: "black",
                color: "white",
                marginTop: "10px",
              }}
              className="bg-black text-white w-[98%] py-2 mb-16 mt-16 rounded-full cursor-pointer"
            >
              Accept & continue
            </Button>
            {isLoading && (
              <div className="mx-auto mt-4 flex">
                <CircularProgress />
              </div>
            )}
            {error && (
              <Alert
                className="w-full sm:w-1/2 mt-4 mx-auto"
                severity="warning"
              >
                store create fail
              </Alert>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreateAnotherStore;
