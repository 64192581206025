/** @format */

import React, { useEffect, useState } from "react";
import OTPModal from "../components/OTPModal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  forgetPasswordSubmitEmail,
  clearVendorStete,
} from "../state/vendorDetails/vendorDetailsSlice";

const ForgetPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forgetPassSendMailFail, forgetPassSendMailSucess } = useSelector(
    (state) => state.vendorReducer
  );

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Bcak to  login page or Close--->
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    navigate("/login");
    setEmail("");
  };

  const handle_email_submit = (event) => {
    event.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    } else {
      dispatch(forgetPasswordSubmitEmail(email));
    }
    setTimeout(() => {
      setSuccess(true);
      setError("");
    }, 1000);
  };

  useEffect(() => {
    if (forgetPassSendMailSucess) {
      toast.success(forgetPassSendMailSucess);
      dispatch(clearVendorStete());
    } else if (forgetPassSendMailFail) {
      toast.error(forgetPassSendMailFail);
      dispatch(clearVendorStete());
    }
  }, [forgetPassSendMailSucess, forgetPassSendMailFail, dispatch]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
        <div className=" w-full flex justify-between">
          <h1 className="text-2xl font-bold text-center mb-4">
            Forgot Password
          </h1>
          <button
            type="button"
            onClick={toggleModal}
            className="text-red-500 font-bold bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form onSubmit={handle_email_submit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white py-2 rounded-md  transition duration-200"
          >
            Get OTP
          </button>
          {error && <div className="mt-4 text-red-500">{error}</div>}
        </form>
      </div>
      {success && <OTPModal email={email} />}
    </div>
  );
};

export default ForgetPage;
