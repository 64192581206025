/** @format */

import { React, useEffect, useState } from "react";
import { GiTakeMyMoney } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerByStore,
  getLuggageByProvider,
  getTolatBagByStore,
} from "../state/luggage/luggageSlice";
import { getOrderListByStore } from "../state/order/orderSlice";
import { vendorBalance } from "../state/vendorDetails/vendorDetailsSlice";
import Layout from "../components/layout";
import "@fontsource/dm-sans";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { fetchStoreReview } from "../state/storeReview/storeReviewSlice";
// import { Avatar } from "@mui/material";
import LuggageImg from "../img/luggage.png";
import Checkmart from "../img/Checkmark.png";
import User from "../img/User.png";
import { storeIncome } from "../state/storeIncome/storeIncomeSlice";
import users from "../img/uSas.png";
import { Rating } from "@mui/material";

Chart.register(...registerables);

const Home = () => {
  const dispatch = useDispatch();

  const { token, loginUser } = useSelector((state) => state.userData);

  const { balance } = useSelector((state) => state.vendorReducer);

  const { selectedStore } = useSelector((state) => state.luggageData);
  const currencyName = selectedStore?.currency;

  const { storeBagQuantity } = useSelector((state) => state.luggageData);
  const reveiws = useSelector((state) => state.storeReview?.data) || [];
  const bookingsUsers = useSelector((state) => state.orderByStore?.orders);

  const { storeCustomer, currentStore } = useSelector(
    (state) => state.luggageData
  );

  const { data: storeBalance } = useSelector((state) => state.storeIncome);
  const [customerData, setCustomerData] = useState({});
  const cards = [
    {
      id: 1,
      title: "Total Luggages",
      logo: (
        <img
          src={LuggageImg}
          alt="Luggage"
          style={{ height: "25px", width: "25px" }}
        />
      ),
      value: storeBagQuantity || 0,
    },
    {
      id: 2,
      title: "Total Customer",
      logo: (
        <img src={User} alt="User" style={{ height: "25px", width: "25px" }} />
      ),
      value: storeCustomer?.length || 0,
    },
    {
      id: 3,
      title: "Earning by store",
      logo: (
        <img
          src={Checkmart}
          alt="Checkmark"
          style={{ height: "25px", width: "25px" }}
        />
      ),
      value: storeBalance?.storeIncome || 0,
    },
  ];

  useEffect(() => {
    dispatch(getTolatBagByStore({ token, storeId: currentStore }));
  }, [dispatch, loginUser.id, token, currentStore]);
  useEffect(() => {
    dispatch(getCustomerByStore({ token, storeId: currentStore }));

    dispatch(fetchStoreReview({ token, storeId: currentStore }));
  }, [dispatch, token, currentStore]);

  useEffect(() => {
    dispatch(vendorBalance({ id: loginUser.id, token }));
    dispatch(getLuggageByProvider({ token, providerId: loginUser.id }));
  }, [dispatch, token, loginUser.id]);

  useEffect(() => {
    dispatch(getOrderListByStore({ storeId: currentStore, token }));
    dispatch(storeIncome({ storeId: currentStore, token }));
  }, [dispatch, currentStore, token]);

  useEffect(() => {
    if (storeCustomer) {
      const monthwiseCustomers = {};
      storeCustomer.forEach((customer) => {
        const startDate = new Date(customer.startDate);
        const month = startDate.toLocaleString("default", { month: "long" });
        monthwiseCustomers[month] = (monthwiseCustomers[month] || 0) + 1;
      });
      setCustomerData(monthwiseCustomers);
    }
  }, [storeCustomer]);

  const chartData = {
    labels: Object.keys(customerData),
    datasets: [
      {
        label: "Number of Customers",
        data: Object.values(customerData),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  let currencyIcon = "";
  if (currencyName === "Pound") {
    currencyIcon = "£";
  } else if (currencyName === "Euro") {
    currencyIcon = "€";
  }

  return (
    <Layout>
      <div className="flex justify-center  ">
        <div className=" flex-col  p-[10px] mt-[60px] mb-[30px] w-full gap-5 justify-center items-start  md:flex-row  ">
          <div className="w-full p-[2px]  gap-[10px] flex flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-col justify-center mt-2 -ml-[2px] ">
            {cards?.map((card) => (
              <div className="bg-white shadow-lg p-3 flex flex-col h-[100px] 2xl:w-[240px] xl:w-[240px] lg:w-[240px] md:w-[100%] sm:w-[100%] max-sm:w-[100%] rounded-lg border-2 border-lightgray">
                <div className="flex items-center mb-2 ml-1">
                  <div className="h-10 w-10 rounded-full bg-[#EBEEF2] flex items-center justify-center mr-2">
                    <div className="mt-[5px]">{card.logo}</div>
                  </div>
                  <div>
                    <div className="whitespace-nowrap overflow-hidden text-ellipsis mb-1 font-poppins">
                      {card?.title}
                    </div>
                    <div className="text-[#1C1C28] font-bold">
                      {card?.title === "Earning by store" ? (
                        <>
                          {currencyIcon}
                          {card?.value?.toFixed(2) || 0}
                        </>
                      ) : (
                        card.value
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="h-[100px] 2xl:w-[240px] xl:w-[240px] lg:w-[240px] md:w-[100%] sm:w-full max-sm:w-full ">
              <div className="flex pl-3 bg-white justify-start items-center border-2 border-gray-300 mb-10 rounded-lg shadow-md h-full w-full">
                <div className="mb-3">
                  <div className="h-10 w-10 rounded-full bg-[#EBEEF2] flex items-center justify-center mr-2">
                    <div className="mt-[5px] text-[3xl] ">
                      {" "}
                      <GiTakeMyMoney />{" "}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-[2px] ">
                  <div className=" flex flex-col">Total Earning</div>
                  <div className="text-gray-700 font-bold ml-5%">
                    {`£ ${balance?.blanceFromUk?.toFixed(2) || 0}`}
                  </div>
                  <div className="text-gray-700 font-bold ml-5%">
                    {`€ ${balance?.blanceFromFr?.toFixed(2) || 0}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end of card holder ---->>>>> */}

          <div className="  gap-[20px] justify-center w-full flex  flex-col lg:flex-row md:flex-col sm:flex-col max-sm:flex-col">
            <div class=" pt-[6px]  2xl:flex-col xl:flex-col lg:flex-col  w-full max-sm:w-[100%] sm:w-[100%] md:w-[100%] lg:w-[62%] xl:w-[62%] 2xl:w-[62%] ">
              <div class="bg-white flex flex-col shadow-lg rounded-md border-2 border-lightgray mt-1 w-full  justify-start">
                <div class="w-full flex flex-col max-w-xs mx-auto">
                  <h3 class="text-md text-[#a5a5a6]">No Of Customers</h3>
                  <h1 class="text-lg text-black font-bold">This Month</h1>
                </div>
                <Bar className="mt-30px " data={chartData} />{" "}
              </div>

              {/* end of ----- chart holderr -----> */}

              <div className="bg-white p-2 flex flex-col shadow-lg rounded-md border-2 border-lightgray mt-6 w-full justify-start">
                <h1 className="text-lg text-black font-extrabold ml-[2px] ">
                  Reviews
                </h1>

                {/* well change it  latter  the upper side one */}

                {reveiws?.map((review) => (
                  <div className="flex justify-between items-center   gap-[8px] ">
                    <div className=" w-[30%] p-[2px] flex items-center ">
                      <div className="p-3 w-[40px] h-[40px] rounded-full bg-slate-300 overflow-hidden flex items-center justify-center">
                        <img
                          src={users}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <h1 className="text-xs text-black font-extrabold ml-2">
                        {review?.userName}
                      </h1>
                    </div>

                    <div className="w-[20%] flex items-center ">
                      <Rating
                        className="text-[10px] "
                        name="read-only "
                        value={review.rating}
                        readOnly
                      />
                    </div>
                    <div className="w-[50%] flex justify-center p-2 ">
                      <p className="text-[10px] sm:text-[8px] md:text-[8px] lg:text-[12px] text-slate-400 font-semibold">
                        {review?.comment}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* end of  review part=====> */}
            </div>
            {/* end of right part---- */}

            <div class="  w-[100%] max-sm:w-[100%] sm:w-[100%] md:w-[100%] lg:w-[35%] xl:w-[35%] 2xl:w-[35%] mr-[8px] ">
              <div className=" ml-[4px]  mt-2 mb-[20px] p-[2px] w-full flex flex-col bg-white rounded-md border-2 border-lightgray ">
                <h1 className="text-base font-bold">BOOKINGS</h1>
                {bookingsUsers?.map((names) => (
                  <div
                    key={names}
                    className=" flex gap-2 mt-1 p-2 items-center bg-[#f4f6f9] rounded-md"
                  >
                    <div className="p-2 bg-white w-[30px] h-[30px] rounded-full ">
                      <img
                        src={users}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <h3 className="text-[#000000] ">
                        {names?.customrName} made a new booking
                      </h3>
                    </div>
                  </div>
                ))}
              </div>

              {/* end of message=====> */}
            </div>
            {/* end of left part----> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
