// adminSignupSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicPost } from "../../utils";

export const vendorSignup = createAsyncThunk(
  "vendor signup",
  async (vendorData, { rejectWithValue }) => {
    try {
      const response = await publicPost("/vendor/signup", vendorData);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const fetchUserData = createAsyncThunk(
  "vendor login",
  async (vendor, { rejectWithValue }) => {
    try {
      const response = await publicPost("/vendor/login", vendor);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const vendorSignupSlice = createSlice({
  name: "vendorSignup",
  initialState: {
    isLoading: false,
    data: null,
    status: null,
    error: null,
    signupSuccess: false,
    token: null,
    loginUser: null,
    loginSuccess: false,
  },
  extraReducers: (builder) => {
    builder.addCase(vendorSignup.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
    });
    builder.addCase(vendorSignup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.loginUser = action.payload;
      state.token = action.payload.api_token?.access;
      state.status = "success";
      state.error = null;
      state.signupSuccess = true;
    });
    builder.addCase(vendorSignup.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.signupSuccess = false;
      state.status = "reject";
    });
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "success";
      state.token = action.payload.api_token.access;
      state.loginUser = action.payload;
      state.error = null;
      state.loginSuccess = true;
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
      state.status = "reject";
    });
  },
  reducers: {
    clearSignupData: (state) => {
      state.signupSuccess = false;
    },

    logout: (state) => {
      state.loginUser = null;
      state.token = null;
      state.loginSuccess = false;
      state.status = null;
    },
  },
});

export const { clearSignupData, logout } = vendorSignupSlice.actions;
export default vendorSignupSlice.reducer;
