/** @format */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  formDataPatch,
  formDataPost,
  privateGet,
  privatePatch,
  publicPatch,
  publicPost,
} from "../../utils/apiCaller";

export const createVendorDetails = createAsyncThunk(
  "createVendorDetails",
  async ({ formDataToSend, id, token }) => {
    try {
      const response = await formDataPost(
        `/vendor/details/${id}`,
        formDataToSend,
        token
      );
      return response.data;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const updateVendorDetails = createAsyncThunk(
  "updateVendorDetails",
  async ({ formDataToSend, id, token }) => {
    try {
      const response = await formDataPatch(
        `/vendor/update/${id}`,
        formDataToSend,
        token
      );
      return response.data;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const vendorProfile = createAsyncThunk(
  "vendorProfile",
  async ({ id, token }) => {
    try {
      const response = await privateGet(`/vendor/details/${id}`, token);
      return response.payload;
    } catch (err) {
      return { error: err.message };
    }
  }
);
// resrt Password
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async ({ id, data, token }, { rejectWithValue }) => {
    try {
      const response = await privatePatch(
        `/vendor/password/reset/${id}`,
        token,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);

// Forget Password ---->

export const forgetPasswordSubmitEmail = createAsyncThunk(
  "forgetPasswordSubmitEmail",
  async (email, { rejectWithValue }) => {
    console.log(email);
    try {
      const response = await publicPost("/vendor/forget/otp", { email });
      return response;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.email : err.message);
    }
  }
);
// ----end 1

export const forgetPasswordSubmitOTP = createAsyncThunk(
  "forgetPasswordSubmitOTP",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await publicPost("/vendor/match/forget/password/otp", {
        email,
        otp,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.email : err.message);
    }
  }
);
// end----2
export const forgetPasswordSetNewPass = createAsyncThunk(
  "forgetPasswordSetNewPass",
  async ({ email, newPassword, confirmPassword }, { rejectWithValue }) => {
    try {
      const response = await publicPatch("/vendor/forget/password", {
        email,
        newPassword,
        confirmPassword,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.email : err.message);
    }
  }
);
// payment reducer

export const vendorBalance = createAsyncThunk(
  "vendorBalance",
  async ({ id, token }) => {
    try {
      const response = await privateGet(`/payment/vendor/balance/${id}`, token);
      console.log("-------------------", response);
      return response.payload;
    } catch (err) {
      return { error: err.message };
    }
  }
);

const vendorDetailsSlice = createSlice({
  name: "vendorDetails",
  initialState: {
    data: null,
    status: "idle",
    error: null,
    vendorProfile: null,
    createDetails: false,
    balance: null,
    passResetSuccess: "",
    passResetFail: "",
    forgetPassSendMailSucess: "",
    forgetPassSendMailFail: "",
    forgetPassOTPSucess: "",
    forgetPassOTPfail: "",
    forgetPass_SetNewPassSuccess: "",
    forgetPass_SetNewPassFail: "",
  },

  extraReducers: (builder) => {
    builder
      .addCase(createVendorDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createVendorDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.createDetails = true;
      })
      .addCase(createVendorDetails.rejected, (state, action) => {
        state.status = "failed";
        state.createDetails = false;
        state.error = action.error.message;
      });

    builder.addCase(vendorProfile.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(vendorProfile.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.vendorProfile = action.payload;
    });
    builder.addCase(vendorProfile.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });

    builder.addCase(updateVendorDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateVendorDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(updateVendorDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });

    builder.addCase(vendorBalance.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(vendorBalance.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.balance = action.payload;
    });
    builder.addCase(vendorBalance.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    // resetpassword
    builder.addCase(resetPassword.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.passResetSuccess = action.payload.status;
    });

    // Forget password
    builder.addCase(forgetPasswordSubmitEmail.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(forgetPasswordSubmitEmail.fulfilled, (state, action) => {
      state.forgetPassSendMailSucess = action.payload.success;
    });
    builder.addCase(forgetPasswordSubmitEmail.rejected, (state, action) => {
      state.forgetPassSendMailFail = action.payload.success;
    });
    // end of ----> 1
    builder.addCase(forgetPasswordSubmitOTP.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(forgetPasswordSubmitOTP.fulfilled, (state, action) => {
      state.forgetPassOTPSucess = action.payload.success;
      state.forgetPassOTPfail = "";
    });
    builder.addCase(forgetPasswordSubmitOTP.rejected, (state, action) => {
      state.forgetPassOTPfail = action.payload;
    });
    // end of --->2
    builder.addCase(forgetPasswordSetNewPass.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(forgetPasswordSetNewPass.fulfilled, (state, action) => {
      state.forgetPass_SetNewPassSuccess = action.payload;
    });
    builder.addCase(forgetPasswordSetNewPass.rejected, (state, action) => {
      state.forgetPass_SetNewPassFail = action.payload.fail;
    });
    // end of --->3
  },
  reducers: {
    clearVendorStete: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
      state.vendorProfile = null;
      state.createDetails = false;
      state.balance = null;
      state.passResetSuccess = "";
      state.passResetFail = "";
      state.forgetPassSendMailSucess = "";
      state.forgetPassSendMailFail = "";
      state.forgetPassOTPSucess = "";
      state.forgetPassOTPfail = "";
    },
  },
});
export const { clearVendorStete } = vendorDetailsSlice.actions;
export default vendorDetailsSlice.reducer;
