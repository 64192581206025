import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { MenuItem, TextField } from "@mui/material";
import { getBankAccount, updateBankAccount } from "../state/Bank/bankSlice";
import { useDispatch, useSelector } from "react-redux";
const UpdateBankAccountModal = ({
  open,
  onClose,
  bankAccount,
}) => {
  const dispatch = useDispatch();
  const countries = ['United Kingdom', 'France'];
  const [country, setCountry] = useState(bankAccount?.country);
  const { token, loginUser } = useSelector((state) => state.userData);
  const [sortCode, setsortCode] = useState(bankAccount?.sortCode || "");
  const [accountNo, setAccountNo] = useState(bankAccount?.accountNo || "");
  const [bankName, setBankName] = useState(bankAccount?.bankName || "");
  const [iban, setIban] = useState(bankAccount?.iban || null);
  const [bic, setBic] = useState(bankAccount?.bic || null);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    const digitsOnly = inputValue?.replace(/\D/g, "");
    const formattedValue = digitsOnly
      ?.match(/.{1,2}/g)
      ?.join("-")
      ?.substr(0, 8);
    setsortCode(formattedValue || "");
  };
  const handleUpdate = async () => {
    const data = {
      id: loginUser.id,
      bankName: bankName,
      accountNo: accountNo,
      sortCode: sortCode,
      iban: iban,
      bic: bic,
      country: country,
    };

    await dispatch(updateBankAccount({ token, data, vendorId: loginUser?.id }));
    await dispatch(getBankAccount({ token, vendorId: loginUser.id }));
    await onClose();
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ color: "black", textAlign: "center" }}
          id="alert-dialog-title"
        >
          Update Bank Account
        </DialogTitle>
        <div style={{ width: "40%", marginLeft: "30%" }}>
          <TextField
            margin="normal"
            id="fkCountry"
            name="fkCountry"
            fullWidth
            style={{ marginLeft: "2%" }}
            select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            variant="outlined"
            focused
            required
          >
            {countries?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "100%", margin: "10px" }}
            label="Bank Name"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />
          <TextField
            style={{ width: "100%", margin: "10px" }}
            label="Account Number"
            value={accountNo}
            onChange={(e) => setAccountNo(e.target.value)}
          />
          {country === "United Kingdom" ? (
            <TextField
              style={{ width: "100%", margin: "10px" }}
              label="Sort Code"
              value={sortCode}
              onChange={handleChange}
            />
          ) : (
            <>
              <TextField
                style={{ width: "100%", margin: "10px" }}
                label="Iban"
                value={iban}
                onChange={(e) => setIban(e.target.value)}
              />
              <TextField
                style={{ width: "100%", margin: "10px" }}
                label="Bic"
                value={bic}
                onChange={(e) => setBic(e.target.value)}
              />
            </>
          )}
          <Button
            style={{
              color: "white",
              backgroundColor: "black",
              marginLeft: "10px",
            }}
            onClick={handleUpdate}
            autoFocus
          >
            Update
          </Button>
        </div>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateBankAccountModal;
