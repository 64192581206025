import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateGet } from "../../utils";

export const storeIncome = createAsyncThunk(
    "storeIncome",
    async ({ storeId, token }) => {
      try {
        const response = await privateGet(`/store/income/record/${storeId}`, token);
        console.log("-------------------", response);
        return response.payload;
      } catch (err) {
        return { error: err.message };
      }
    }
  );

  const storeIncomeSlice = createSlice({
    name: "storeIncome",
    initialState: {
      status: "idle",
      error: null,
      data: null,
    },
  
    extraReducers: (builder) => {
      builder.addCase(storeIncome.pending, (state) => {
        state.status = "loading";
      });
      builder.addCase(storeIncome.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      });
      builder.addCase(storeIncome.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    },
  });

  export default storeIncomeSlice.reducer;