/** @format */

import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Avatar, IconButton } from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { vendorProfile } from "../state/vendorDetails/vendorDetailsSlice";
import {
  resetPassword,
  clearVendorStete,
} from "../state/vendorDetails/vendorDetailsSlice";
import { stringAvatar } from "../utils/nameAvater";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const { token, loginUser } = useSelector((state) => state.userData);
  const { passResetSuccess } = useSelector((state) => state.vendorReducer);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(vendorProfile({ id: loginUser.id, token }));
  }, [dispatch, token, loginUser.id]);

  const userNameCapitalFormation = loginUser.name?.toUpperCase();

  // rest password
  const [isModalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    validatePasswords(event.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    validatePasswords(newPassword, event.target.value);
  };

  const validatePasswords = (newPassword, confirmPassword) => {
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleSubmit = () => {
    const data = {
      oldPassword,
      newPassword,
      confirmPassword,
    };
    dispatch(resetPassword({ id: loginUser.id, data, token }));
  };

  useEffect(() => {
    if (passResetSuccess) {
      toast.success(passResetSuccess);
      dispatch(clearVendorStete());
      setModalOpen(false);
      navigate("/login");
    }
  }, [passResetSuccess, navigate, dispatch]);

  return (
    <>
      <Layout>
        <section className=" shadow-2xl items-center py-3  mt-[30px] ">
          <div className="h-full pb-7 pt-6 ">
            <div className="border-b-2 block md:flex ">
              <div className="w-full md:w-3/5 pt-2 bg-white lg:ml-4 shadow-md ">
                <h4 className="  text-center text-black font-sans">
                  Your Personal Information
                </h4>
                <div className="flex justify-center ">
                  <IconButton className="mt-[5px] mb-[10px] p-0">
                    <Avatar {...stringAvatar(userNameCapitalFormation)} />
                  </IconButton>
                </div>
                <div className="rounded shadow p-4">
                  <div className="pb-6">
                    <label
                      htmlFor="name"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Role
                    </label>
                    <div className="flex">
                      <input
                        disabled
                        id="username"
                        className="border-1 rounded-r px-4 py-2 w-full"
                        type="text"
                        value={loginUser.role}
                      />
                    </div>
                  </div>
                  {/* end role */}
                  <div className="pb-6">
                    <label
                      htmlFor="name"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Name
                    </label>
                    <div className="flex">
                      <input
                        disabled
                        id="username"
                        className="border-1 rounded-r px-4 py-2 w-full"
                        type="text"
                        value={loginUser.name}
                      />
                    </div>
                  </div>

                  {/* end name */}
                  <div className="pb-4">
                    <label
                      htmlFor="about"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Email
                    </label>
                    <input
                      disabled
                      id="email"
                      className="border-1 rounded-r px-4 py-2 w-full"
                      type="email"
                      value={loginUser.email}
                    />
                  </div>
                  {/* ned email== */}
                  <div className="pb-4">
                    <label
                      htmlFor="about"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Mobile
                    </label>
                    <input
                      disabled
                      id="email"
                      className="border-1 rounded-r px-4 py-2 w-full"
                      type="number"
                      value={loginUser.mobile}
                    />
                  </div>
                  {/* ned mobile== */}
                  <div className="pb-4">
                    <label
                      htmlFor="about"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Status
                    </label>
                    <input
                      disabled
                      id="email"
                      className="border-1 rounded-r px-4 py-2 w-full"
                      type="text"
                      value={loginUser.status}
                    />
                  </div>
                  {/* ned end tsatus== */}
                </div>
                {/* end form */}
                <div className=" px-4 flex justify-center items-center mt-[15px] h-[45px] w-[100%] ">
                  <div>
                    <button
                      onClick={toggleModal}
                      class=" mb-4 bg-gray-800 hover:bg-black h-[40px] text-white font-bold py-2 px-4 rounded inline-flex items-center"
                    >
                      Change Password
                    </button>

                    {isModalOpen && (
                      <div
                        id="authentication-modal"
                        className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50"
                      >
                        <div className="relative p-4 w-full max-w-md max-h-full bg-white rounded-lg shadow dark:bg-gray-700">
                          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                              Reset Your Password
                            </h3>
                            <button
                              type="button"
                              onClick={toggleModal}
                              className="text-red-500 font-bold bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                              </svg>
                              <span className="sr-only">Close modal</span>
                            </button>
                          </div>
                          <div className="p-4 md:p-5">
                            <form onSubmit={handleSubmit} className="space-y-4">
                              <div>
                                <label
                                  htmlFor="password"
                                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                  Old Password
                                </label>
                                <div className="relative">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    required
                                    placeholder="••••••••"
                                    onChange={handleOldPasswordChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                  />
                                  <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                  >
                                    {showPassword ? (
                                      <svg
                                        className="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.274.837-.677 1.615-1.192 2.298M15 12a3 3 0 01-6 0"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        className="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M13.875 18.825A10.92 10.92 0 0112 19c-4.478 0-8.27-2.943-9.543-7A10.926 10.926 0 0112 5c1.276 0 2.496.231 3.625.661M19.932 19.932a10.937 10.937 0 01-3.025 1.953m-9.822-1.953A10.924 10.924 0 013.513 3.513m16.974 16.974a10.925 10.925 0 001.953-3.025M5.878 5.878a10.932 10.932 0 00-1.953 3.025M12 5c1.276 0 2.496.231 3.625.661M12 19c-1.276 0-2.496-.231-3.625-.661M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19.932 19.932l-4.36-4.36m0 0L3.515 3.515"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              </div>
                              {/* old pass */}
                              <div>
                                <label
                                  htmlFor="password"
                                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                  Now Password
                                </label>
                                <div className="relative">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder="••••••••"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    required
                                  />
                                  <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                  >
                                    {showPassword ? (
                                      <svg
                                        className="w-4 h-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.274.837-.677 1.615-1.192 2.298M15 12a3 3 0 01-6 0"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        className="w-4 h-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M13.875 18.825A10.92 10.92 0 0112 19c-4.478 0-8.27-2.943-9.543-7A10.926 10.926 0 0112 5c1.276 0 2.496.231 3.625.661M19.932 19.932a10.937 10.937 0 01-3.025 1.953m-9.822-1.953A10.924 10.924 0 013.513 3.513m16.974 16.974a10.925 10.925 0 001.953-3.025M5.878 5.878a10.932 10.932 0 00-1.953 3.025M12 5c1.276 0 2.496.231 3.625.661M12 19c-1.276 0-2.496-.231-3.625-.661M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19.932 19.932l-4.36-4.36m0 0L3.515 3.515"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              </div>
                              {/* new pass */}

                              <div>
                                <label
                                  htmlFor="password"
                                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                  Confirm Password
                                </label>
                                <div className="relative">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder="••••••••"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    required
                                  />
                                  <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                  >
                                    {showPassword ? (
                                      <svg
                                        className="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-.274.837-.677 1.615-1.192 2.298M15 12a3 3 0 01-6 0"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        className="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M13.875 18.825A10.92 10.92 0 0112 19c-4.478 0-8.27-2.943-9.543-7A10.926 10.926 0 0112 5c1.276 0 2.496.231 3.625.661M19.932 19.932a10.937 10.937 0 01-3.025 1.953m-9.822-1.953A10.924 10.924 0 013.513 3.513m16.974 16.974a10.925 10.925 0 001.953-3.025M5.878 5.878a10.932 10.932 0 00-1.953 3.025M12 5c1.276 0 2.496.231 3.625.661M12 19c-1.276 0-2.496-.231-3.625-.661M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19.932 19.932l-4.36-4.36m0 0L3.515 3.515"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              </div>
                              {/* end of --> confirm pass */}
                              {errorMessage && (
                                <p className="text-sm text-red-500">
                                  Passwords do not match
                                </p>
                              )}
                              <button
                                type="submit"
                                className="w-full text-white bg-gray-900 hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                disabled={errorMessage}
                              >
                                Reset Now
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <button class="bg-gray-300 hover:bg-gray-400 h-[40px] text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    Home
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          {/*  */}
        </section>
      </Layout>
    </>
  );
};

export default Profile;
