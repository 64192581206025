/** @format */

import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPasswordSubmitOTP,
  forgetPasswordSetNewPass,
  clearVendorStete,
} from "../state/vendorDetails/vendorDetailsSlice";

const OTPModal = ({ email: initialEmail, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forgetPassOTPSucess, forgetPassOTPfail } = useSelector(
    (state) => state.vendorReducer
  );

  // Bcak to  login page or Close--->
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    navigate("/login");
    setEmail("");
  };

  // state ---->
  const [email, setEmail] = useState(initialEmail);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState("");
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handle_submit_otp = (e) => {
    e.preventDefault();

    if (forgetPassOTPfail || "OTP verification failed.") {
      console.log("====================================");
      console.log(forgetPassOTPfail);
      console.log("====================================");
    }

    if (otp.includes("")) {
      setOtpError("Please fill all the fields.");
    } else {
      setOtpError("");
      const otpAsNumber = parseInt(otp.join(""), 10);
      dispatch(forgetPasswordSubmitOTP({ email, otp: otpAsNumber }));
      if (!forgetPassOTPfail) {
        setIsPasswordModalVisible(true);
     }
    }
  };

  useEffect(() => {
    if (forgetPassOTPSucess) {
      toast.success(forgetPassOTPSucess);
      dispatch(clearVendorStete());
    } else if (forgetPassOTPfail) {
      setIsPasswordModalVisible(false)
      toast.error(forgetPassOTPfail);
      // dispatch(clearVendorStete());
    }
  }, [forgetPassOTPSucess, forgetPassOTPfail, dispatch]);

  const handleClose = () => {
    setIsPasswordModalVisible(false);
    if (onClose) onClose();
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
          <header className="mb-5">
            <div className="flex justify-between">
              <h1 className="text-2xl font-bold mb-1">Email Verification</h1>
              <button
                type="button"
                onClick={toggleModal}
                className="text-red-300 font-bold bg-transparent hover:bg-gray-200 hover:text-red-500 rounded-lg text-sm w-8 h-8 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <p className="text-[15px] text-slate-500">
              Enter the 4-digit verification code sent to {email}.
            </p>
          </header>
          <form onSubmit={handle_submit_otp}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center gap-3 mb-4">
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={value}
                  onChange={(e) => handleChange(e, index)}
                  className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                  maxLength="1"
                />
              ))}
            </div>
            {otpError && <div className="text-red-500 mb-4">{otpError}</div>}
            <button
              type="submit"
              className="w-full bg-black text-white py-2 rounded-md  transition duration-200"
            >
              Verify Your Account
            </button>
          </form>
          <div className="text-sm text-slate-500 mt-4">
            Didn't receive code?{" "}
            <a
              className="font-medium text-black hover:text-gray-500"
              href="/forgetpassword"
            >
              Resend
            </a>
          </div>
        </div>
      </div>

      {isPasswordModalVisible &&
      forgetPassOTPfail !== "OTP verification failed." ? (
        <ChangePasswordModal email={email} onClose={handleClose} />
      ) : (
        ""
      )}
    </>
  );
};

const ChangePasswordModal = ({ email, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgetPass_SetNewPassSuccess, forgetPass_SetNewPassFail } =
    useSelector((state) => state.vendorReducer);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Password macthng handle ---->
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    validatePasswords(event.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    validatePasswords(newPassword, event.target.value);
  };

  const validatePasswords = (newPassword, confirmPassword) => {
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  // end of ---->

  const handlw_update_newPassword = (e) => {
    e.preventDefault();
    dispatch(forgetPasswordSetNewPass({ email, newPassword, confirmPassword }));
    navigate("/login");
  };

  useEffect(() => {
    if (forgetPass_SetNewPassSuccess) {
      toast.success(forgetPass_SetNewPassSuccess);
      dispatch(clearVendorStete());
    }
    if (forgetPass_SetNewPassFail) {
      toast.error(forgetPass_SetNewPassFail);
      dispatch(clearVendorStete());
    }
  }, [forgetPass_SetNewPassFail, forgetPass_SetNewPassSuccess, dispatch]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
        <div className="flex justify-between">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Create New Password
          </h2>
        </div>

        <form
          className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
          onSubmit={handlw_update_newPassword}
        >
          <div className="">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={email}
              readOnly
            />
          </div>

          <div>
            <label
              htmlFor="confirm-password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              New password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="confirm-password"
                id="new-password"
                placeholder="••••••••"
                value={newPassword}
                onChange={handleNewPasswordChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <svg
                  className="h-4 text-gray-700 cursor-pointer"
                  fill="none"
                  onClick={togglePasswordVisibility}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  style={{ display: showPassword ? "block" : "none" }}
                >
                  <path
                    fill="currentColor"
                    d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                  />
                </svg>

                <svg
                  className="h-4 text-gray-700 cursor-pointer"
                  fill="none"
                  onClick={togglePasswordVisibility}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  style={{ display: showPassword ? "none" : "block" }}
                >
                  <path
                    fill="currentColor"
                    d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* end of new password filed-----> */}

          <div>
            <label
              htmlFor="confirm-password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Confirm password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="confirm-password"
                id="confirm-password"
                placeholder="••••••••"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <svg
                  className="h-4 text-gray-700 cursor-pointer"
                  fill="none"
                  onClick={togglePasswordVisibility}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  style={{ display: showPassword ? "block" : "none" }}
                >
                  <path
                    fill="currentColor"
                    d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                  />
                </svg>

                <svg
                  className="h-4 text-gray-700 cursor-pointer"
                  fill="none"
                  onClick={togglePasswordVisibility}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  style={{ display: showPassword ? "none" : "block" }}
                >
                  <path
                    fill="currentColor"
                    d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* end of confirm password filed-----> */}

          {errorMessage && (
            <p className="text-sm text-red-500">Passwords do not match</p>
          )}
          {/* end of error message ---> */}
          <button
            type="submit"
            className="w-full text-white bg-black hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Create New Password
          </button>
          {/*  end of rest button ---> */}
          <button
            type="button"
            className="mt-4 w-full bg-red-500 text-white hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-gray-400 dark:hover:text-gray-200 dark:focus:ring-gray-700"
            onClick={onClose}
          >
            Cancel
          </button>
          {/* end of cancel button ---> */}
        </form>
      </div>
    </div>
  );
};

export default OTPModal;
