import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/layout";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createVendorDetails } from "../state/vendorDetails/vendorDetailsSlice";
import { useNavigate } from "react-router-dom";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import {
  fetchCity,
  fetchCountry,
} from "../state/countryAndCity/countryAndCitySlice";
import appConfig from "../config/config";

const BecomeVendor = () => {
  const [mapLat, setMapLat] = useState("");
  const [mapLan, setMapLan] = useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const dispatch = useDispatch();
  const { createDetails } = useSelector((state) => state.vendorReducer);
  const { loginUser, token } = useSelector((state) => state.userData);
  const { country, city } = useSelector((state) => state.countryAndCity);
  const [formData, setFormData] = useState({
    aboutMe: "",
    addressLineOne: "",
    addressLineTwo: "",
    businessAddress: "",
    email: "",
    fkCity: "",
    fkCountry: "",
    fullName: "",
    businessName: "",
    mobileNo: "",
    zipCode: "",
    profileimage: null,
    idProofFile: null,
    addressProofFile: null,
  });
  useEffect(() => {
    dispatch(fetchCountry(token));
    dispatch(fetchCity(token));
  }, [dispatch,token]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };
  const navigate = useNavigate();
  const inputRef = useRef();
  const handlePaceChange = () => {
    const [place] = inputRef.current.getPlaces();

    if (place) {
      setMapLan(place.geometry.location.lng());
      setMapLat(place.geometry.location.lat());
      setGoogleAddress(place.formatted_address);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("aboutMe", formData.aboutMe);
    formDataToSend.append("addressLineOne", formData.addressLineOne);
    formDataToSend.append("addressLineTwo", formData.addressLineTwo);
    formDataToSend.append("businessAddress", formData.businessAddress);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("fkCity", formData.fkCity);
    formDataToSend.append("fkCountry", formData.fkCountry);
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("googleAddress", googleAddress);
    formDataToSend.append("googleAddressLat", mapLat);
    formDataToSend.append("businessName", formData.businessName);
    formDataToSend.append("googleAddressLng", mapLan);
    formDataToSend.append("mobileNo", formData.mobileNo);
    formDataToSend.append("zipCode", formData.zipCode);
    formDataToSend.append("profileimage", formData.profileimage);
    formDataToSend.append("idProofFile", formData.idProofFile);
    formDataToSend.append("addressProofFile", formData.addressProofFile);

    dispatch(createVendorDetails({ formDataToSend, id: loginUser.id, token }));
    setFormData({
      aboutMe: "",
      addressLineOne: "",
      addressLineTwo: "",
      businessAddress: "",
      email: "",
      fkCity: "",
      fkCountry: "",
      fullName: "",
      googleAddress: "",
      googleAddressLat: "",
      businessName: "",
      googleAddressLng: "",
      mobileNo: "",
      zipCode: "",
      profileimage: null,
      idProofFile: null,
      addressProofFile: null,
    });
    if (createDetails) navigate("/");
  };

  return (
    <Layout>
      <h1 style={{ marginTop: "65px", marginLeft: "25%" }}>Become a vendor</h1>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { marginLeft: "25%", width: "50%" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="email"
          name="email"
          label="Email"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />
        <TextField
          id="fullName"
          name="fullName"
          label="Full Name"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />
        <TextField
          id="mobileNo"
          name="mobileNo"
          label="Mobile No"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />
        <TextField
          id="aboutMe"
          name="aboutMe"
          label="About Me"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />
        <TextField
          id="Business Name"
          name="businessName"
          label="Business Name"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />

        <TextField
          id="addressLineOne"
          name="addressLineOne"
          label="Address Line One"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />

        <TextField
          id="addressLineTwo"
          name="addressLineTwo"
          label="Address Line Two"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />

        <TextField
          id="businessAddress"
          name="businessAddress"
          label="Business Address"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />
        <TextField
          id="fkCountry"
          name="fkCountry"
          label="Country"
          select
          defaultValue=""
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        >
          {country?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.countryName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="fkCity"
          name="fkCity"
          label="City"
          select
          defaultValue=""
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        >
          {city?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.cityName}
            </MenuItem>
          ))}
        </TextField>
        <label>Location</label>
        <LoadScript
          googleMapsApiKey={appConfig.googleMapsApiKey}
          libraries={["places"]}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePaceChange}
          >
            <input
              style={{
                width: "100%",
                padding: "5px",
                marginBottom: "10px",
                borderRadius: "5px",
                height: "50px",
                border: "1px solid gray",
              }}
              type="text"
              className="form-control"
              placeholder="Enter your location"
              required
            />
          </StandaloneSearchBox>
        </LoadScript>

        <TextField
          id="zipCode"
          name="zipCode"
          label="Zip Code"
          onChange={handleChange}
          variant="outlined"
          sx={{ m: 2 }}
        />

        <label>Profile image</label>
        <input
          id="profileImage"
          name="profileimage"
          type="file"
          onChange={handleFileChange}
        />
        <label>ID proof file</label>
        <input
          id="idProofFile"
          name="idProofFile"
          type="file"
          onChange={handleFileChange}
        />
        <label>Address proof File</label>
        <input
          id="addressProofFile"
          name="addressProofFile"
          type="file"
          onChange={handleFileChange}
        />

        <Button type="submit" variant="contained" sx={{ m: 2 }}>
          Submit
        </Button>
      </Box>
    </Layout>
  );
};

export default BecomeVendor;
