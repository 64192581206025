/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { clearSignupData, fetchUserData } from "../state/signup/signupSlice";
import { Alert, CircularProgress } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const image1 = require("../img/Pic01.png");
const image2 = require("../img/Pic02.png");
const image3 = require("../img/Pic03.png");
const image4 = require("../img/Pic04.png");

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://doorap.com/">
        doorap.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}


export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  React.useEffect(() => {
    dispatch(clearSignupData());
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData = {
      credential: data.get("email"),
      password: data.get("password"),
    };
    dispatch(fetchUserData(loginData))
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Login error:", error);
      });
  };

  const { error, status } = useSelector((state) => state.userData);

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Box sx={{ width: "100vw", height: isMobile ? "auto" : "100vh" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}
      >
        <Grid
          item
          lg={6}
          xs={12}
          sx={{
            height: "100%",
            display: "block",
          }}
        >
          <Carousel
            showThumbs={false}
            infiniteLoop
            autoPlay
            dynamicHeight={false}
            interval={5000}
            style={{ height: "100%" }}
          >
            <div>
              <img
                style={{
                  height: isMobile ? "50vh" : "100vh",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={image1}
                alt="banner1"
              />
            </div>
            <div>
              <img
                style={{
                  height: isMobile ? "50vh" : "100vh",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={image2}
                alt="banner1"
              />
            </div>
            <div>
              <img
                style={{
                  height: isMobile ? "50vh" : "100vh",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={image3}
                alt="banner1"
              />
            </div>
            <div>
              <img
                style={{
                  height: isMobile ? "50vh" : "100vh",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={image4}
                alt="banner1"
              />
            </div>
          </Carousel>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: isMobile ? "20px" : "40px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: isMobile ? "20px" : "10px",
            }}
          >
            <Typography
              style={{
                marginLeft: isMobile ? "5%" : "25%",
                fontFamily: "poppins",
                marginBottom: "10px",
              }}
              component="h1"
              variant="h5"
            >
              Sign In
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
              style={{
                width: isMobile ? "90%" : "50%",
                marginLeft: isMobile ? "5%" : "25%",
              }}
            >
              <label
                style={{
                  fontFamily: "poppins",
                  marginBottom: "10px",
                  color: "gray",
                }}
              >
                Email address
              </label>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                style={{ width: "100%" }}
              />
              <br />
              <label
                style={{
                  fontFamily: "poppins",
                  marginBottom: "10px",
                  color: "gray",
                }}
              >
                Your password
              </label>
              <TextField
                style={{ width: "100%" }}
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <Link
                style={{ marginLeft: isMobile ? "53%" : "50%", color: "black" }}
                href="/forgetpassword"
              >
                Forget your password
              </Link>{" "}
              <br />
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  background: "black",
                  width: "50%",
                  borderRadius: "16px",
                }}
              >
                Sign In
              </Button>
              {status === "pending" && (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              )}
              {error ? <Alert severity="warning">{error}</Alert> : ""}
              <Grid container>
                <Grid item>
                  <Link
                    href="/signup"
                    variant="body2"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {"Don't have an account?"}{" "}
                    <span style={{ fontWeight: "bold" }}>Sign Up</span>
                  </Link>
                  <Copyright sx={{ mt: 2, mb: 4 }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
