/** @format */

import * as React from "react";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { AccountCircle } from "@mui/icons-material";
import { Avatar, Card, CardContent, Dialog, Menu } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../state/signup/signupSlice";
import { Button } from "@mui/base";
import { stringAvatar } from "../utils/nameAvater";
import "@fontsource/dm-sans";
import {
  clearVendorStete,
  vendorProfile as vendorProfileData,
} from "../state/vendorDetails/vendorDetailsSlice";
import {
  clearLuggageState,
  clickedCurrentStore,
  setClickedStore,
} from "../state/luggage/luggageSlice";
import { clearOrderState } from "../state/order/orderSlice";
const drawerWidth = 250;

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { token, loginUser } = useSelector((state) => state.userData);
  React.useEffect(() => {
    dispatch(vendorProfileData({ id: loginUser.id, token }));
  }, [dispatch, token, loginUser.id]);
  const userNameCapitalFormation = loginUser.name?.toUpperCase();
  React.useEffect(() => {
    // let title = "";
    if (pathname === "/") {
      // title = "Dashboard";
    } else if (pathname === "/order/List") {
      // title = "Bookings";
    } else if (pathname === "/payment") {
      // title = "Payment";
    } else if (pathname === "/store/review") {
      // title = "Reviews";
    } else if (pathname === "store/list") {
      // title = "Your Store";
    } else if (pathname === "ask/create/store") {
      // title = "Create Store";
    }
    // document.title = title ? `${title} |Doorap partner` : "Doorap partner";
  }, [pathname]);

  const { vendorProfile } = useSelector((state) => state.vendorReducer);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { name } = useSelector((state) => state.userData?.loginUser);
  const handleLogout = async () => {
    await dispatch(logout());
    await dispatch(clearLuggageState());
    await dispatch(clearOrderState());
    await dispatch(clearVendorStete());
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpenModal = () => {
    // setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const { providerStoreList, currentStore } = useSelector(
    (state) => state.luggageData
  );

  const selectedStore = providerStoreList.find(
    (item) => item.id === parseInt(currentStore)
  );
  const defaultStore = providerStoreList[0]?.id || 0;
  const [storeId, setStoreId] = React.useState(currentStore || defaultStore);

  React.useEffect(() => {
    dispatch(clickedCurrentStore(storeId || defaultStore));
    dispatch(setClickedStore(selectedStore || providerStoreList[0]));
  }, [providerStoreList, selectedStore, dispatch, defaultStore, storeId]);

  const drawer = (
    <div>
      <Sidebar />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="flex shadow-none">
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          backgroundColor: "white",
        }}
      >
        <div className=" flex justify-between items-center w-[100%] h-[60px]  pl-[5px] pr-[3px] ">
          <div className="flex items-center  w-full max-sm:w-[130px] sm:w-[130px] md:w-[200px]  lg:w-[20%] xl:w-[240px] 2xl:w-[250px]  h-[40px]   ml-[2px] ">
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" } }}
              >
                <MenuIcon className="text-black" />
              </IconButton>
            </div>
            <div className="p-[1px] ml-[4px] font-semibold text-black text-xl max-sm:text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl">
              {pathname === "/" ? (
                <h1 className=" text-black ml-[0px]  text-xl max-sm:text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl">
                  <span className="text-[#FBAF4C]">Hi</span> {name}
                </h1>
              ) : pathname === "/order/List" ? (
                "Bookings"
              ) : pathname === "/payment" ? (
                "Payments"
              ) : pathname === "/store/review" ? (
                "Reviews"
              ) : pathname === "store/list" ? (
                "Your Store"
              ) : pathname === "ask/create/store" ? (
                "Create store"
              ) : (
                ""
              )}
            </div>
          </div>

          {/* end right side ----->>>>> */}
          <div className="  p-[2px] flex justify-between items-center   gap-[15px] mr-[15px] ">
            <div className=" 2xl:w-[280px] xl:w-[350px] lg:w-[300px] md:w-[200px] sm:w-[150px] max-sm:w-[150px] ">
              <select
                value={storeId || defaultStore}
                onChange={(e) => setStoreId(e.target.value || defaultStore)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                {providerStoreList.map((store) => (
                  <option
                    key={store?.id || defaultStore}
                    value={store?.id || defaultStore}
                  >
                    {store?.businessName}
                  </option>
                ))}
              </select>
            </div>

            {/* end of form control--- >>*/}
            <div className="w-[40px] h-[40px] rounded-full">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                style={{
                  border: "1px solid red",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#f9fafb",
                }}
              >
                {vendorProfile?.vendorDetailsData?.profileUrl ? (
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    src={vendorProfile?.vendorDetailsData?.profileUrl}
                    alt="profile"
                  />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
              <Menu
                className="bg-none"
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClickOpenModal}
                  component={Link}
                  to="/Profile"
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </Menu>

              {open && (
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <Box>
                    <Card
                      sx={{ minWidth: 275 }}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          height: "80px",
                          width: "100%",
                          backgroundColor: "SlateGrey",
                        }}
                      >
                        <Typography
                          style={{
                            color: "black",
                            textAlign: "center",
                            fontWeight: "bold",
                            padding: "5px",
                            fontFamily: "DM-Sans",
                          }}
                          variant="h6"
                        >
                          Profile
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            sx={{ p: 0 }}
                            style={{ marginTop: "20px" }}
                          >
                            <Avatar
                              {...stringAvatar(userNameCapitalFormation)}
                            />
                          </IconButton>
                        </div>
                      </div>

                      <CardContent>
                        <Typography
                          style={{
                            padding: "5px",
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          {loginUser?.role}
                        </Typography>
                        <Typography
                          style={{ padding: "5px", textAlign: "center" }}
                        >
                          Email: {loginUser?.email}
                        </Typography>
                        <Typography
                          style={{ padding: "5px", textAlign: "center" }}
                        >
                          Name: {loginUser?.name}
                        </Typography>
                        <Typography
                          style={{ padding: "5px", textAlign: "center" }}
                        >
                          Mobile: {loginUser?.mobile}
                        </Typography>
                        <Typography
                          style={{ padding: "5px", textAlign: "center" }}
                        >
                          Status: {loginUser?.status}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>

                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      border: 0,
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </Dialog>
              )}
            </div>
          </div>
          {/* end of left part ---->>>> */}
        </div>
        {/* menu bar ====>>>> */}
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
