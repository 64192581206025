/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStoreReview } from "../state/storeReview/storeReviewSlice";
import Layout from "../components/layout";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
// import CircularProgress from "@mui/material/CircularProgress";
// import Rating from "@mui/material/Rating";
// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// import "@fontsource/dm-sans";
// import LinearProgress from "@mui/material/LinearProgress";
import Avatar from "@mui/material/Avatar";

const Review = () => {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.storeReview);
  const { currentStore } = useSelector((state) => state.luggageData);
  const { token } = useSelector((state) => state.userData);

  useEffect(() => {
    dispatch(fetchStoreReview({ storeId: currentStore, token }));
  }, [dispatch, currentStore, token]);

  const reviewsLength = data ? data.length : 0;

  const countReviewsByRating = (rating) => {
    return data?.filter((review) => review?.rating === rating).length;
  };

  return (
    <Layout>
      <div className=" flex flex-col mt-[75px]  ">
        <div className=" p-[8px]  mb-5">
          <h1 className="text-xl ml-[8px] text-black font-semibold ">
            My Store Reviews
          </h1>
        </div>

        <div className="w-11/12 md:w-[450px] mt-2 bg-white rounded-lg shadow-none my-10 md:my-0 ml-4 border-2 border-lightgray">
          <div className="p-6 pb-1">
            <h3 className="text-2xl font-bold mb-5 font-poppins">
              {reviewsLength} reviews
            </h3>
            {[5, 4, 3, 2, 1].map((rating) => (
              <div key={rating} className="flex items-center mb-2">
                {countReviewsByRating(rating) > 0 && (
                  <>
                    <span className="text-orange-500 mr-2 text-2xl font-poppins">
                      ★
                    </span>
                    <span className="mr-2 whitespace-nowrap font-poppins">
                      {rating} star
                    </span>
                    <div className="flex items-center w-full">
                      <div className="w-4/5 mr-2">
                        <div className="relative h-1 bg-gray-200">
                          <div
                            className="absolute top-0 left-0 h-full bg-orange-500"
                            style={{
                              width: `${
                                (countReviewsByRating(rating) / reviewsLength) *
                                100
                              }%`,
                            }}
                          />
                        </div>
                      </div>
                      <span className="whitespace-nowrap font-poppins">
                        {countReviewsByRating(rating)} reviews
                      </span>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* end of rating top -----> */}

        <div className="mt-[20px] p-[16px]   mb-10">
          {status === "loading" && (
            <div className="flex justify-center items-center">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-700"></div>
            </div>
          )}

          {status === "failed" && (
            <p className="text-red-500">Error: {error}</p>
          )}

          {status === "succeeded" && data && data.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {data.map((review) => (
                <div
                  key={review.id}
                  className="bg-white rounded-lg shadow-none border-2 border-lightgray p-4 flex flex-col justify-between"
                >
                  <div className="flex items-center mb-4">
                    {review.avatarUrl ? (
                      <img
                        className="w-12 h-12 rounded-full bg-gray-200"
                        alt={review.userName}
                        src={review.avatarUrl}
                      />
                    ) : (
                      <Avatar className="w-12 h-12" />
                    )}
                    <div className="ml-4">
                      <h4 className="text-lg font-semibold font-dm-sans">
                        {review.userName}
                      </h4>
                    </div>
                  </div>
                  <div className="flex flex-col mt-1">
                    <div className="flex items-center mb-2">
                      {[...Array(5)].map((_, i) => (
                        <span
                          key={i}
                          className={`text-yellow-500 ${
                            i < review.rating ? "" : "text-gray-300"
                          }`}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                    <p className="text-black text-sm font-poppins ml-1 mt-1">
                      {review.comment}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-lg">
              {status !== "loading" && "No reviews available"}
            </p>
          )}
        </div>

        {/* end ---> */}
      </div>
    </Layout>
  );
};

export default Review;
