/** @format */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateGet } from "../../utils/apiCaller";

export const fetchStoreReview = createAsyncThunk(
  "fetchStoreReview",
  async ({ storeId, token }) => {
    try {
      const response = await privateGet(
        `/luggage/store/review/${storeId}`,
        token
      );
      return response.payload;
    } catch (err) {
      return { error: err.message };
    }
  }
);

const storeReviewSlice = createSlice({
  name: "storeReview",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreReview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStoreReview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchStoreReview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.data = [];
      });
  },
});

export default storeReviewSlice.reducer;
