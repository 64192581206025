import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicPost } from "../../utils";

export const sendOtp = createAsyncThunk(
  "otp",
  async (vendorData, { rejectWithValue }) => {
    try {
      const response = await publicPost("/vendor/otp", vendorData);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const otpSlice = createSlice({
  name: "otp",
  initialState: {
    isLoading: false,
    emailValidation: null,
    error: null,
    otpSendSuccess: false,
    signupData: null,
  },
  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emailValidation = action.payload;
      state.error = null;
      state.otpSendSuccess = true;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.otpSendSuccess = false;
    });
  },
  reducers: {
    signupData: (state, { payload }) => {
      state.signupData = payload;
    },
    clearSignupData: (state) => {
      state.signupData = null;
    },
  },
});
export const { signupData, clearSignupData } = otpSlice.actions;
export default otpSlice.reducer;
