/** @format */

import React from "react";
import Navbar from "../Navbar";
import "@fontsource/dm-sans";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row p-0  ">
      <Navbar />
      <div className="w-[100%]  " >
        {children}
      </div>
    </div>
  );
};

export default Layout;
