// store.js

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import { vendorReducer } from "./vendorDetails";
import { otpReducer, signupReducer } from "./signup";
import { luggageReducer } from "./luggage";
import { bankAccountReducer } from "./Bank";
import { luggageStoreReviewReducer } from "./storeReview";
import { bookingReducer } from "./order";
import { paymentReducer } from "./payment";
import { countryAndCityReducer } from "./countryAndCity";
import { storeIncomeReducer } from "./storeIncome";


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  vendorReducer: vendorReducer,
  signup: signupReducer,
  emailOtp: otpReducer,
  userData: signupReducer,
  luggageData: luggageReducer,
  bankInformation: bankAccountReducer,
  storeReview: luggageStoreReviewReducer,
  orderByStore: bookingReducer,
  paymentInfo: paymentReducer,
  countryAndCity: countryAndCityReducer,
  storeIncome:storeIncomeReducer

});

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);
