/** @format */

import React, { useEffect, useState, useCallback } from "react";
import Layout from "../components/layout";
import {
  Button,
  TablePagination,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getOrderListByStore } from "../state/order/orderSlice";
import {
  changeBookingStatus,
  getLuggageByProvider,
  updateLuggageStatus,
} from "../state/luggage/luggageSlice";
import frTmeFormat from "../utils/localTimeFormat";
import "@fontsource/dm-sans";

const OrderPage = () => {
  const dispatch = useDispatch();
  const { token, loginUser } = useSelector((state) => state.userData);
  const { currentStore, selectedStore } = useSelector(
    (state) => state.luggageData
  );
  const { orders } = useSelector((state) => state.orderByStore);
  console.log("====================================");
  console.log(orders);
  console.log("====================================");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [searchField, setSearchField] = useState("trackingCode");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = useCallback(() => {
    const filteredData = orders?.filter((order) => {
      if (searchField === "trackingCode") {
        return (
          typeof order.trackingCode === "string" &&
          order?.trackingCode
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase())
        );
      } else if (searchField === "orderId") {
        return (
          typeof order.bookingId === "number" &&
          order?.bookingId
            ?.toString()
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())
        );
      }
      return false;
    });
    setFilteredOrders(filteredData);
  }, [searchField, searchQuery, orders]);

  const handleStatusSelect = (event) => {
    setSelectedStatus(event.target.value);
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);
  useEffect(() => {
    dispatch(getOrderListByStore({ storeId: currentStore, token }));
  }, [dispatch, currentStore, token]);

  const handleStatus = async (status, id) => {
    await dispatch(
      changeBookingStatus({ bookingId: id, token, data: { status } })
    );
    await dispatch(getOrderListByStore({ storeId: currentStore, token }));
  };
  const handlePuse = async () => {
    await dispatch(
      updateLuggageStatus({ id: currentStore, token: token, data: {} })
    );
    await dispatch(getLuggageByProvider({ token, providerId: loginUser.id }));
  };

  return (
    <Layout>
      <div className="mt-[75px]  flex flex-col justify-center items-center p-[12px]">
        <div className="flex justify-between    md:w-full p-[1px]  ">
          <div className="  w-[100%]  grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 ">
            <div>
              <TextField
                select
                variant="outlined"
                size="small"
                fullWidth
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
              >
                <MenuItem value="trackingCode">Tracking Code</MenuItem>
                <MenuItem value="orderId">Order ID</MenuItem>
              </TextField>
            </div>
            {/* end ----> */}
            <div>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleSearch}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* end ----> */}
            <div className="sm:w-full">
              <TextField
                select
                label="Status"
                variant="outlined"
                size="small"
                fullWidth
                value={selectedStatus}
                onChange={handleStatusSelect}
              >
                <MenuItem value="Picked-off">Picked-off</MenuItem>
                <MenuItem value="Drop-off">Drop-off</MenuItem>
                <MenuItem value="Dropped">Dropped</MenuItem>
              </TextField>
            </div>
            {/* end of text field pick up options ---> */}
            <div>
              {selectedStore?.status === "ACTIVE" ? (
                <Button
                  fullWidth
                  style={{
                    color: "white",
                    backgroundColor: "#D61A00",
                    border: 0,
                    marginBottom: "10px",
                    borderRadius: "5px",
                    padding: "7px",
                  }}
                  onClick={handlePuse}
                >
                  Pause New Bookings
                </Button>
              ) : (
                <Button
                  fullWidth
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    border: 0,
                    marginBottom: "10px",
                    borderRadius: "5px",
                    padding: "7px",
                  }}
                  onClick={handlePuse}
                >
                  Take New Bookings
                </Button>
              )}
            </div>
            {/* end of  button ---> */}
          </div>
        </div>
        {/* end of text fild and button ---> */}

        <div className="   w-[100%] 2xl:w-[100%] xl:w-[100%] md:w-[100%] sm:w-[100%] max-sm:w-[100%] mt-3  relative overflow-x-auto shadow-sm sm:rounded-[5px] ">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs  text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  BOOKING PLACE
                </th>
                <th
                  scope="col"
                 className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  ORDER ID
                </th>
                <th
                  scope="col"
                 className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  STATUS
                </th>
                <th
                  scope="col"
                 className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  BAGS
                </th>
                <th
                  scope="col"
                 className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  CUSTOMER NAME
                </th>
                <th
                  scope="col"
                  className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  TRACKING CODE
                </th>
                <th
                  scope="col"
                  className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  BOOKING DURATION
                </th>
                <th
                  scope="col"
                  className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  IMAGE
                </th>
                <th
                  scope="col"
                 className="px-[8px] text-[#6B7280]  font-semibold items-center ml-[4px]  py-3 text-nowrap 2xl:text-md xl:text-md lg:text-[14px] md:text-[10px] sm:text-[10px] max-sm:text-[10px] "
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders
                ?.filter(
                  (row) => !selectedStatus || row.status === selectedStatus
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <tr
                    key={row?.id}
                    className={`${
                      index % 2 === 0
                        ? "bg-white dark:bg-gray-900"
                        : "bg-gray-50 dark:bg-gray-800"
                    } border-b dark:border-gray-700`}
                  >
                    <td className="px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white">
                      {frTmeFormat(row?.createdAt)}
                    </td>
                    <td className=" pl-[8px] px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white">
                      {row?.bookingId}
                    </td>
                    <td className="pl-[2px] px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white ">
                      <span
                        className="py-[4px] px-[4px] text-nowrap rounded"
                        style={{
                          color:
                            row.status === "Drop-off"
                              ? "#92400E"
                              : row.status === "Picked-off"
                              ? "#065F46"
                              : "#1E40AF",
                          backgroundColor:
                            row.status === "Drop-off"
                              ? "#FCD34D"
                              : row.status === "Picked-off"
                              ? "#D1FAE5"
                              : "#DBEAFE",
                        }}
                      >
                        {row?.status}
                      </span>
                    </td>
                    <td className="pl-[10px] px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white">
                      {row?.quantity}
                    </td>
                    <td className="pl-[25px] px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white ">
                      {row?.customrName}
                    </td>
                    <td className="pl-[18px] px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white ">
                      {row?.trackingCode}
                    </td>
                    <td className="px-2 px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white">
                      {frTmeFormat(row?.startDate)} - {frTmeFormat(row.endDate)}
                    </td>
                    <td className="px-2 px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white">
                   <a href={row?.imageUrl} target="blank" style={{textDecoration:"none",color:"white",backgroundColor:"#050636", padding:"10px",borderRadius:"5px"}}>View</a>
                    </td>
                    <td className="px-2 px-2 py-2 font-normal text-[#6B7280] 2x:text-[14px] xl:text-[14px] lg:text-[8px] md:text-[8px] sm:text-[8px] max-sm:text-[8px]  whitespace-nowrap dark:text-white">
                      {row.status === "Drop-off" ? (
                        <button
                          onClick={() => handleStatus("Dropped", row.id)}
                          className="py-1 px-2 rounded"
                          style={{
                            color: "#1E40AF",
                            backgroundColor: "#DBEAFE",
                          }}
                        >
                          Dropped
                        </button>
                      ) : row.status === "Dropped" ? (
                        <button
                          onClick={() => handleStatus("Picked-off", row.id)}
                          className="py-1 px-2 rounded"
                          style={{
                            color: "#065F46",
                            backgroundColor: "#D1FAE5",
                          }}
                        >
                          Picked-off
                        </button>
                      ) : (
                        <span style={{ color: "MediumSeaGreen" }}>
                          COMPLETE
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* end of table ---> */}

        <TablePagination
          className=" mt-2"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* end of table pagaination -----> */}
      </div>
    </Layout>
  );
};

export default OrderPage;
