/** @format */

import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";
import {
  getCustomerByStore,
  getLuggageByProvider,
} from "../state/luggage/luggageSlice";
import { getBankAccount } from "../state/Bank/bankSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  vendorProfile,
  vendorBalance,
} from "../state/vendorDetails/vendorDetailsSlice";
import { getOrderListByStore } from "../state/order/orderSlice";
import { PieChart } from "@mui/x-charts/PieChart";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { transectionHistory } from "../state/payment/paymentSlice";
import frTmeFormat from "../utils/localTimeFormat";
import BankAccountModal from "../components/BankAccountModal";
import UpdateBankAccountModal from "../components/UpdateBankAccountModal";
import InfoIcon from "@mui/icons-material/Info";

const PaymentPage = () => {
  const dispatch = useDispatch();
  const { balance } = useSelector((state) => state.vendorReducer);
  const { selectedStore, storeCustomer } = useSelector(
    (state) => state.luggageData
  );
  const { token, loginUser } = useSelector((state) => state.userData);
  const { bankAccount } = useSelector((state) => state.bankInformation);
  const { tnxHistory } = useSelector((state) => state.paymentInfo);

  useEffect(() => {
    dispatch(getBankAccount({ token, vendorId: loginUser.id }));
    dispatch(getLuggageByProvider({ token, providerId: loginUser.id }));
    dispatch(vendorProfile({ id: loginUser.id, token }));
    dispatch(vendorBalance({ id: loginUser.id, token }));
    dispatch(getOrderListByStore({ token, providerId: loginUser?.id }));
    dispatch(transectionHistory({ token, vendorId: loginUser?.id }));
    dispatch(getCustomerByStore({ token, storeId: selectedStore?.id }));
  }, [dispatch, token, loginUser.id, selectedStore?.id]);

  const monthPriceData = storeCustomer.map((customer) => ({
    month: new Date(customer.createdAt).getMonth(),
    price: customer.price,
  }));

  const earningsPerMonth = monthPriceData.reduce((acc, curr) => {
    const month = curr.month;
    const price = curr.price;
    acc[month] = (acc[month] || 0) + price;
    return acc;
  }, {});

  const pieChartData = Object.keys(earningsPerMonth).map((month) => ({
    name: `Month ${parseInt(month) + 1}`,
    value: earningsPerMonth[month],
  }));

  const pieChartSize = {
    width: 300,
    height: 200,
  };

  // const currencyIcon = selectedStore?.currency === "Pound" ? "£" : "£";
  // const currencyText = selectedStore?.currency === "Pound" ? "GBP" : "EURO";
  const [isCreateBankModalOpen, setIsCreateBankModalOpen] = useState(false);
  const [isUpdateBankModalOpen, setIsUpdateBankModalOpen] = useState(false);

  const handleOpenCreateBankAccount = () => {
    setIsCreateBankModalOpen(true);
  };

  const handleCloseCreateBankModal = () => {
    setIsCreateBankModalOpen(false);
  };

  const handleUpdateModal = () => {
    setIsUpdateBankModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateBankModalOpen(false);
  };

  return (
    <>
      <Layout>
        <div className="mt-[85px]  flex  flex-col justify-center mx-[12px]  ">
          <div className="flex w-[100%] p-[5px] text-white bg-[#FF5733] rounded-[7px] justify-center mb-[40px] ">
            <InfoIcon />
            <Typography
              style={{
                marginLeft: "5px",
                fontFamily: "Poppins",
              }}
            >
              Payments are made end of every month
            </Typography>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  marginLeft: "2%",
                  marginTop: "5px",
                }}
                variant="h6"
                gutterBottom
              >
                Transaction History
              </Typography>
              <Table style={{ border: 1 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Payment Info</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tnxHistory?.map((tnx) => (
                    <TableRow key={tnx.id}>
                      <TableCell>{tnx?.status}</TableCell>
                      <TableCell>{frTmeFormat(tnx?.updatedAt)}</TableCell>
                      <TableCell>{tnx?.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                style={{
                  width: "100%",
                  border: ".2px solid LightGray",
                  borderRadius: "15px",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      fontFamily: "DM sans",
                    }}
                  >
                    Balance
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "38px",
                      fontWeight: "900",
                      fontFamily: "DM sans",
                      marginTop: "10px",
                      marginBottom: "-14px",
                    }}
                  >
                    {`€ ${balance?.blanceFromFr.toFixed(2)}`}
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "DM sans",
                        marginLeft: "12px",
                      }}
                    >
                      EURO
                    </span>
                    <br></br>
                    {`£ ${balance?.blanceFromUk.toFixed(2)}`}

                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "DM sans",
                        marginLeft: "12px",
                      }}
                    >
                      GBP
                    </span>
                  </Typography>
                </CardContent>
              </Card>

              <Card
                style={{
                  marginTop: "10px",
                  border: ".2px solid LightGray",
                  borderRadius: "15px",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      // fontFamily: "DM-sans",
                    }}
                  >
                    Bank Accounts
                  </Typography>
                  <div
                    style={{
                      alignItems: "center",
                      marginRight: "30px",
                    }}
                  >
                    {bankAccount?.id ? (
                      <div>
                        {bankAccount?.sortCode &&
                        bankAccount?.country === "United Kingdom" ? (
                          <Typography>
                            <span style={{ fontWeight: "bold" }}>SC: </span>
                            {bankAccount?.sortCode}
                          </Typography>
                        ) : (
                          ""
                        )}
                        {bankAccount?.iban &&
                        bankAccount?.country !== "United Kingdom" ? (
                          <Typography>
                            <span style={{ fontWeight: "bold" }}>Iban: </span>
                            {bankAccount?.iban}
                          </Typography>
                        ) : (
                          ""
                        )}
                        {bankAccount?.bic &&
                        bankAccount?.country !== "United Kingdom" ? (
                          <Typography>
                            <span style={{ fontWeight: "bold" }}>Bic: </span>
                            {bankAccount?.bic}
                          </Typography>
                        ) : (
                          ""
                        )}

                        {bankAccount?.accountNo ? (
                          <Typography>
                            <span style={{ fontWeight: "bold" }}>
                              Account No:{" "}
                            </span>
                            {bankAccount?.accountNo}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {!bankAccount?.id ? (
                      <Button
                        onClick={handleOpenCreateBankAccount}
                        style={{
                          backgroundColor: "#EBEEF2",
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          paddingRight: "2px",
                          marginTop: "20px",
                        }}
                      >
                        ADD ACCOUNT
                        <AddCircleOutlineIcon style={{ height: "18px" }} />
                      </Button>
                    ) : (
                      <Button
                        onClick={handleUpdateModal}
                        style={{
                          backgroundColor: "#EBEEF2",
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          paddingRight: "2px",
                          marginTop: "20px",
                        }}
                      >
                        Update ACCOUNT
                        <AddCircleOutlineIcon style={{ height: "18px" }} />
                      </Button>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card
                style={{
                  marginTop: "10px",
                  border: ".2px solid LightGray",
                  borderRadius: "15px",
                }}
              >
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        gutterBottom
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        Last Three Months
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "lightgray" }}
                      >
                        Earning from last three months
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <PieChart
                        series={[{ data: pieChartData, innerRadius: 50 }]}
                        {...pieChartSize}
                      ></PieChart>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Layout>
      <BankAccountModal
        open={isCreateBankModalOpen}
        onClose={handleCloseCreateBankModal}
        selectedStore={selectedStore}
        bankAccount={bankAccount}
      />
      <UpdateBankAccountModal
        open={isUpdateBankModalOpen}
        onClose={handleCloseUpdateModal}
        selectedStore={selectedStore}
        bankAccount={bankAccount}
      />
    </>
  );
};

export default PaymentPage;
