import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet } from "../../utils";

export const getOrderListByStore = createAsyncThunk(
  "otp",
  async ({ token, storeId }, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/luggage/booking/${storeId}`, token);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const storeOrderSlice = createSlice({
  name: "order list",
  initialState: {
    isLoading: false,
    orders: null,
    error: null,
    orderGetSuccess: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderListByStore.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderListByStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
      state.error = null;
      state.orderGetSuccess = true;
    });
    builder.addCase(getOrderListByStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.orderGetSuccess = false;
      state.orders = [];
    });
  },
  reducers: {
    clearOrderState: (state) => {
      state.isLoading = false;
      state.orders = null;
      state.error = null;
      state.orderGetSuccess = false;
    },
  },
});
export const { clearOrderState } = storeOrderSlice.actions;
export default storeOrderSlice.reducer;
