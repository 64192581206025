import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/layout";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

import {
  getLuggageDetails,
  updateLuggage,
} from "../state/luggage/luggageSlice";
import { useNavigate } from "react-router-dom";

const StoreDetails = () => {
  const { selectedStore } = useSelector((state) => state.luggageData);
  const { country, city } = useSelector((state) => state.countryAndCity);
  const userReducer = useSelector((state) => state.userData);
  const provider = userReducer.loginUser;
  const { token } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getLuggageDetails({ token, storeId: selectedStore?.id }));
  }, [dispatch, token, selectedStore?.id]);

  const [storeName, setStoreName] = useState(selectedStore?.businessName);
  const [providerName, setProviderName] = useState(selectedStore?.providerName);
  const [providerEmail, setProviderEmail] = useState(
    selectedStore?.providerEmail
  );
  const [providerMobile, setProviderMobile] = useState(
    selectedStore?.providerMobile
  );
  const [providerID, setProviderID] = useState(selectedStore?.providerID);
  const [cityName, setCityName] = useState(selectedStore?.cityName);
  const [countryName, setCountryName] = useState(selectedStore?.countryName);
  const [mapLat, setMapLat] = useState(selectedStore?.mapLat);
  const [mapLan, setMapLan] = useState(selectedStore?.mapLan);
  const [address, setAddress] = useState(selectedStore?.address);
  const [zipCode, setZipCode] = useState(selectedStore?.zipCode);
  const [road, setRoad] = useState(selectedStore?.road);
  const [house, setHouse] = useState(selectedStore?.house);
  const [price, setPrice] = useState(selectedStore?.price);
  const [openingTime, setOpeningTime] = useState(selectedStore?.openingTime);
  const [closingTime, setClosingTime] = useState(selectedStore?.closingTime);
  const [allDay, setAllDay] = useState(selectedStore?.allDay);
  const openingTimeHour = openingTime?.$H;
  const openingTimeMinute = openingTime?.$m;

  const formattedOpeningTime = `${openingTimeHour
    ?.toString()
    .padStart(2, "0")}:${openingTimeMinute?.toString().padStart(2, "0")}`;
  const closingTimeHour = closingTime?.$H;
  const closingTimeMinute = closingTime?.$m;

  const formattedCloseingTime = `${closingTimeHour
    ?.toString()
    .padStart(2, "0")}:${closingTimeMinute?.toString().padStart(2, "0")}`;
  const handleAllDay = () => {
    if (allDay === false) {
      return setAllDay(true);
    }
    return setAllDay(false);
  };
  useEffect(() => {
    if (selectedStore) {
      setOpeningTime(formattedOpeningTime);
      setClosingTime(formattedCloseingTime);
    }
  }, [selectedStore, formattedOpeningTime, formattedCloseingTime]);

  useEffect(() => {
    if (provider) {
      setProviderName(provider.name);
      setProviderEmail(provider.email);
      setProviderID(provider.id);
      setProviderMobile(provider.mobile);
    }
  }, [provider]);
  const inputRef = useRef();
  const handlePaceChange = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setMapLan(place.geometry.location.lng());
      setMapLat(place.geometry.location.lat());
    }
  };

  const handleSubmit = () => {
    const data = {
      storeName,
      providerName,
      providerEmail,
      providerMobile,
      providerID,
      countryName,
      cityName,
      mapLat,
      mapLan,
      address,
      zipCode,
      road,
      house,
      price,
      openingTime,
      closingTime,
      allDay,
    };
    const id = selectedStore?.id;
    dispatch(updateLuggage({ token, id, data }));
    navigate("/store/list");
  };

  return (
    <Layout>
      <h3
        style={{ marginTop: "75px", textAlign: "center", fontFamily: "serif" }}
      >
        Store Seetings
      </h3>
      <Box
        component="form"
        style={{ width: "50%", marginLeft: "25%" }}
        noValidate
        autoComplete="off"
      >
        <TextField
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          id="storeName"
          label="Store Name"
          variant="outlined"
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
          required
        />
        <TextField
          id="fkCountry"
          name="fkCountry"
          label="Country"
          select
          value={countryName}
          onChange={(e) => setCountryName(e.target.value)}
          variant="outlined"
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          required
        >
          {country?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="fkCity"
          name="fkCity"
          label="City"
          select
          value={cityName}
          onChange={(e) => setCityName(e.target.value)}
          variant="outlined"
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          required
        >
          {city?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          id="address"
          label="Address"
          variant="outlined"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
        <br />
        <label for="location" style={{ marginLeft: "2px" }}>
          Enter Your Location
        </label>
        <br />

        <LoadScript
          googleMapsApiKey="AIzaSyCVFllfHUhHvKxAMepxcA-72zEoFiOITVk"
          libraries={["places"]}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePaceChange}
          >
            <input
              style={{
                width: "78%",
                padding: "5px",
                marginBottom: "10px",
                borderRadius: "5px",
                height: "50px",
                border: "1px solid gray",
                marginLeft: "5px",
              }}
              type="text"
              className="form-control"
              placeholder="Enter your location"
              required
            />
          </StandaloneSearchBox>
        </LoadScript>

        <TextField
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          id="zipCode"
          label="Zip Code"
          variant="outlined"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          required
        />
        <TextField
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          id="road"
          label="Road"
          variant="outlined"
          value={road}
          onChange={(e) => setRoad(e.target.value)}
          required
        />
        <TextField
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          id="house"
          label="House"
          variant="outlined"
          value={house}
          onChange={(e) => setHouse(e.target.value)}
          required
        />
        <TextField
          style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
          id="price"
          label="Price"
          variant="outlined"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
        <FormControlLabel
          control={<Checkbox onChange={handleAllDay} />}
          label="24hr Open"
          checked={allDay}
        />
        {allDay === false && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
              <TimePicker
                style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
                label="Store Opening time"
                value={
                  openingTime instanceof Date
                    ? openingTime.toLocaleTimeString()
                    : ""
                }
                onChange={(time) => setOpeningTime(time)}
              />
              <TimePicker
                style={{ width: "80%", padding: "5px", marginBottom: "10px" }}
                label="Store Closing time"
                value={
                  closingTime instanceof Date
                    ? closingTime.toLocaleTimeString()
                    : ""
                }
                onChange={(time) => setClosingTime(time)}
              />
            </DemoContainer>
          </LocalizationProvider>
        )}

        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{
            width: "80%",
            padding: "5px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          Update
        </Button>
      </Box>
    </Layout>
  );
};

export default StoreDetails;
