/** @format */

import React, { useRef, useState } from "react";
import Layout from "../components/layout";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  TextField,
  Typography,
 
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateVendorDetails } from "../state/vendorDetails/vendorDetailsSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const VendorSettings = () => {
  const [mapLat, setMapLat] = useState("");
  const [mapLan, setMapLan] = useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const dispatch = useDispatch();
  const { createDetails } = useSelector((state) => state.vendorReducer);
  const { loginUser, token } = useSelector((state) => state.userData);
  const { vendorData, vendorDetailsData } = useSelector(
    (state) => state.vendorReducer.vendorProfile
  );
  const [formData, setFormData] = useState({
    aboutMe: vendorDetailsData?.aboutMe || "",
    addressLineOne: vendorDetailsData?.addressLineOne || "",
    addressLineTwo: vendorDetailsData?.addressLineTwo || "",
    businessAddress: vendorDetailsData?.businessAddress || "",
    email: vendorDetailsData?.email || "",
    fkCity: vendorDetailsData?.fkCity || "",
    fkCountry: vendorDetailsData?.fkCountry || "",
    fullName: vendorDetailsData?.fullName || "",
    businessName: vendorDetailsData?.businessName || "",
    mobileNo: vendorDetailsData?.mobileNo || "",
    zipCode: vendorDetailsData?.zipCode || "",
    profileimage: vendorDetailsData?.profileimage || "",
    idProofFile: vendorDetailsData?.idProofFile || "",
    addressProofFile: vendorDetailsData?.addressProofFile || "",
  });

  const currencies = [
    { value: 1, label: "France" },
    { value: 2, label: "UK" },
  ];

  const cities = [
    { value: 1, label: "Paris" },
    { value: 2, label: "London" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };
  const navigate = useNavigate();
  const inputRef = useRef();
  const handlePaceChange = () => {
    const [place] = inputRef.current.getPlaces();

    if (place) {
      setMapLan(place.geometry.location.lng());
      setMapLat(place.geometry.location.lat());
      setGoogleAddress(place.formatted_address);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("aboutMe", formData.aboutMe);
    formDataToSend.append("addressLineOne", formData.addressLineOne);
    formDataToSend.append("addressLineTwo", formData.addressLineTwo);
    formDataToSend.append("businessAddress", formData.businessAddress);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("fkCity", formData.fkCity);
    formDataToSend.append("fkCountry", formData.fkCountry);
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("googleAddress", googleAddress);
    formDataToSend.append("googleAddressLat", mapLat);
    formDataToSend.append("businessName", formData.businessName);
    formDataToSend.append("googleAddressLng", mapLan);
    formDataToSend.append("mobileNo", formData.mobileNo);
    formDataToSend.append("zipCode", formData.zipCode);
    formDataToSend.append("profileimage", formData.profileimage);
    formDataToSend.append("idProofFile", formData.idProofFile);
    formDataToSend.append("addressProofFile", formData.addressProofFile);

    dispatch(updateVendorDetails({ formDataToSend, id: loginUser.id, token }));
    setFormData({
      aboutMe: "",
      addressLineOne: "",
      addressLineTwo: "",
      businessAddress: "",
      email: "",
      fkCity: "",
      fkCountry: "",
      fullName: "",
      googleAddress: "",
      googleAddressLat: "",
      businessName: "",
      googleAddressLng: "",
      mobileNo: "",
      zipCode: "",
      profileimage: null,
      idProofFile: null,
      addressProofFile: null,
    });
    if (createDetails) navigate("/");
  };

  return (
    <Layout>
      <Typography
        variant="h4"
        sx={{ marginTop: "65px",  }}
      >
        Your Information
      </Typography>
      {vendorData.status !== "PENDING" ? (
        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              m: 2,
              width: { xs: "90%", sm: "80%", md: "50%" },
              marginLeft: { xs: "5%", md: "25%" },
            },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            id="email"
            name="email"
            label="Email"
            onChange={handleChange}
            defaultValue={vendorDetailsData?.email}
            variant="outlined"
            fullWidth
          />
          <TextField
            id="fullName"
            name="fullName"
            label="Full Name"
            onChange={handleChange}
            defaultValue={vendorDetailsData?.fullName}
            variant="outlined"
            fullWidth
          />
          <TextField
            id="mobileNo"
            name="mobileNo"
            label="Mobile No"
            onChange={handleChange}
            defaultValue={vendorDetailsData?.mobileNo}
            variant="outlined"
            fullWidth
          />
          <TextField
            id="aboutMe"
            name="aboutMe"
            label="About Me"
            onChange={handleChange}
            variant="outlined"
            defaultValue={vendorDetailsData?.aboutMe}
            fullWidth
          />
          <TextField
            id="businessName"
            name="businessName"
            defaultValue={vendorDetailsData?.businessName}
            label="Business Name"
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            id="addressLineOne"
            name="addressLineOne"
            label="Address Line One"
            onChange={handleChange}
            variant="outlined"
            defaultValue={vendorDetailsData?.addressLineOne}
            fullWidth
          />
          <TextField
            id="addressLineTwo"
            name="addressLineTwo"
            label="Address Line Two"
            onChange={handleChange}
            defaultValue={vendorDetailsData?.addressLineTwo}
            variant="outlined"
            fullWidth
          />
          <TextField
            id="businessAddress"
            name="businessAddress"
            label="Business Address"
            onChange={handleChange}
            defaultValue={vendorDetailsData?.businessAddress}
            variant="outlined"
            fullWidth
          />
          <TextField
            id="fkCountry"
            name="fkCountry"
            label="Country"
            select
            defaultValue={vendorDetailsData?.fkCountry}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="fkCity"
            name="fkCity"
            label="City"
            select
            defaultValue={vendorDetailsData?.fkCity}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          >
            {cities.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Location
          </Typography>
          <LoadScript
            googleMapsApiKey="AIzaSyBxgJmS9RNOnpiasONTvGUGgS7GRGsH7cg"
            libraries={["places"]}
          >
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePaceChange}
            >
              <input
                style={{
                  width: "100%",
                  padding: "5px",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  height: "50px",
                  border: "1px solid gray",
                }}
                type="text"
                className="form-control"
                placeholder="Enter your location"
                defaultValue={vendorDetailsData?.googleAddress}
                required
              />
            </StandaloneSearchBox>
          </LoadScript>
          <TextField
            id="zipCode"
            name="zipCode"
            label="Zip Code"
            onChange={handleChange}
            defaultValue={vendorDetailsData?.zipCode}
            variant="outlined"
            fullWidth
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Profile image
          </Typography>
          <input
            id="profileImage"
            name="profileimage"
            type="file"
            onChange={handleFileChange}
            style={{ marginBottom: "10px" }}
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            ID proof file
          </Typography>
          <input
            id="idProofFile"
            name="idProofFile"
            type="file"
            onChange={handleFileChange}
            style={{ marginBottom: "10px" }}
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Address proof File
          </Typography>
          <input
            id="addressProofFile"
            name="addressProofFile"
            type="file"
            onChange={handleFileChange}
            style={{ marginBottom: "10px" }}
          />
          <Button type="submit" variant="contained" sx={{ m: 2 }}>
            Submit
          </Button>
        </Box>
      ) : (
        <Card sx={{ minWidth: 275, mx: "auto", width: "fit-content" }}>
          <CardContent>
            <Typography style={{ padding: "5px" }}>
              Name: {vendorData?.name}
            </Typography>
            <Typography style={{ padding: "5px" }}>
              Email: {vendorData?.email}
            </Typography>
            <Typography style={{ padding: "5px" }}>
              Mobile: {vendorData?.mobile}
            </Typography>
            <Typography style={{ padding: "5px" }}>
              Status: {vendorData?.status}
            </Typography>
            <Typography style={{ padding: "5px" }}>
              Role: {vendorData?.role}
            </Typography>
          </CardContent>
          <Typography style={{ padding: "5px", color: "Chocolate" }}>
            Your Account is incomplete{" "}
          </Typography>
          <CardActions>
            <NavLink to="/become/vendor">Complete Your Account</NavLink>
          </CardActions>
        </Card>
      )}
    </Layout>
  );
};

export default VendorSettings;
