/** @format */

import * as React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { vendorSignup } from "../state/signup/signupSlice";
import "@fontsource/poppins";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { MenuItem } from "@mui/material";
import { CircularProgress } from "@mui/material";
var countries = require("countries-cities").getCountries();
const logo = require("../img/logo.png");
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://doorap.com/">
        doorap.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signup = useSelector((state) => state.signup);
  const { signupSuccess, error, status } = signup;

  const [filled, setFilled] = useState({
    name: false,
    lastName: false,
    email: false,
    mobile: false,
    password: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilled((prevState) => ({
      ...prevState,
      [name]: !!value.trim(),
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const vendorData = {
      name: data.get("name"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      mobile: data.get("mobile"),
      password: data.get("password"),
      regType: "email",
      country: data.get("country"),
    };

    dispatch(vendorSignup(vendorData));
  };

  React.useEffect(() => {
    if (signupSuccess) {
      navigate("/create/store");
    }
  }, [signupSuccess, navigate]);

  return (
    <ThemeProvider theme={createTheme()}>
      <div className="flex justify-center mr-[20px] ">
        <img
          style={{
            height: "70px",
            marginTop: "20px",
            marginLeft: "1%",
          }}
          src={logo}
          alt="logo"
        />
      </div>
      <Container
        component="main"
        maxWidth="xs"
        style={{ marginTop: "30px", marginBottom: "40px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          style={{ fontFamily: "Poppins" }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{
              marginBottom: "20px",
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          >
            Basic Information
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <label style={{ color: "#666666" }}>First Name</label>
            <span style={{ color: "red" }}>
              {!filled.name && !signupSuccess && " *is required"}
            </span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              placeholder="Jane"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={handleChange}
            />
            <label style={{ color: "#666666" }}>Last Name</label>
            <span style={{ color: "red" }}>
              {!filled.lastName && !signupSuccess && " *is required"}
            </span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              placeholder="Sarah"
              name="lastName"
              autoComplete="lastName"
              autoFocus
              onChange={handleChange}
            />
            <label style={{ color: "#666666", marginBottom: "10px" }}>
              Country Name
            </label>{" "}
            <br />
            <div className=" h-[4px] "></div>
            <TextField
              style={{ marginTop: "10px", marginBottom: "10px" }}
              id="country"
              name="country"
              select
              onChange={handleChange}
              variant="outlined"
              defaultValue="France"
              fullWidth
              placeholder="Select country"
              required
            >
              {countries?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>{" "}
            <br />
            <label style={{ color: "#666666" }}>Mobile Number</label>
            <span style={{ color: "red" }}>
              {!filled.mobile && !signupSuccess && " *is required"}
            </span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="mobile"
              placeholder="000000000"
              name="mobile"
              autoComplete="mobile"
              autoFocus
              onChange={handleChange}
            />
            <label style={{ color: "#666666" }}>Email</label>
            <span style={{ color: "red" }}>
              {!filled.email && !signupSuccess && " *is required"}
            </span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="123@gmail.com"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <label style={{ color: "#666666" }}>Password</label>
            <span style={{ color: "red" }}>
              {!filled.password && !signupSuccess && " *is required"}
            </span>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                color: "white",
                backgroundColor: "black",
                borderRadius: "30px",
                height: "40px",
                fontWeight: "bold",
              }}
            >
              Next
            </Button>
            {status === "pending" && (
              <Box style={{ marginLeft: "40%" }} sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
            {error && (
              <Typography variant="body2" color="error" align="center">
                {error.message}
              </Typography>
            )}
            <Grid container>
              <Grid item>
                <Link
                  href="/login"
                  variant="body2"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    fontSize: "16px",
                  }}
                >
                  {"Already Registered?"}{" "}
                  <span style={{ fontWeight: "bold" }}>{"Login"}</span>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default Signup;
