import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateGet, privatePatch, privatePost } from "../../utils";

export const createBankAccount = createAsyncThunk(
  "createVendor Bank Account",
  async ({ token, data }) => {
    try {
      const response = await privatePost("/bank/account", token, data);
      return response.data;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const getBankAccount = createAsyncThunk(
  "get Bank Account",
  async ({ token, vendorId }) => {
    try {
      const response = await privateGet(`/bank/account/${vendorId}`, token);
      return response.payload;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const updateBankAccount = createAsyncThunk(
  "update Bank Account",
  async ({ token, data, vendorId }) => {
    try {
      const response = await privatePatch(
        `/bank/update/${vendorId}`,
        token,
        data
      );
      return response.data;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const bankAccountSlice = createSlice({
  name: "bankAccount",
  initialState: {
    isLoading: false,
    bankAccount: null,
    error: null,
    account: false,
  },
  extraReducers: (builder) => {
    builder.addCase(createBankAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createBankAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(createBankAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.message;
    });
    builder.addCase(getBankAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBankAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankAccount = action.payload;
      state.error = null;
      state.account = true;
    });
    builder.addCase(getBankAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.account = false;
      state.error = action.payload.response.data.message;
    });
    builder.addCase(updateBankAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBankAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(updateBankAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.message;
    });
  },
});
export default bankAccountSlice.reducer;
