import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  formDataPatch,
  formDataPost,
  privateGet,
  privatePatch,
  privatePost,
  privatePut,
} from "../../utils";

export const createLuggage = createAsyncThunk(
  "createLuggaeStore",
  async ({ token, data }) => {
    try {
      const response = await formDataPost("/luggage/store/create", data, token);
      return response.data;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const updateStore = createAsyncThunk(
  "update Store",
  async ({ storeId, token, data }) => {
    try {
      const response = await formDataPatch(
        `/luggage/store/update/${storeId}`,
        data,
        token
      );
      return response.data;
    } catch (err) {
      return { error: err.message };
    }
  }
);
export const updateLuggage = createAsyncThunk(
  "update luggage",
  async (params, { rejectWithValue }) => {
    try {
      const response = await privatePatch(
        `/luggage/store/update/${params.id}`,
        params.token,
        params.data
      );

      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const changeBookingStatus = createAsyncThunk(
  "change booking status",
  async (params, { rejectWithValue }) => {
    try {
      const response = await privatePatch(
        `/luggage/booking/status/${params.bookingId}`,
        params.token,
        params.data
      );

      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateLuggageStatus = createAsyncThunk(
  "update luggage status",
  async (params, { rejectWithValue }) => {
    try {
      const response = await privatePut(
        `/luggage/store/status/${params.id}`,
        params.token,
        params.data
      );

      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getLuggageByProvider = createAsyncThunk(
  "get luggage stores",
  async ({ token, providerId }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/luggage/store/provider/${providerId}`,
        token
      );

      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getLuggageDetails = createAsyncThunk(
  "get luggage details",
  async ({ token, storeId }, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/luggage/store/${storeId}`, token);

      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getCustomerByStore = createAsyncThunk(
  "get customer by store",
  async ({ token, storeId }, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/luggage/customer/${storeId}`, token);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getTolatBagByStore = createAsyncThunk(
  "get total bag store",
  async ({ token, storeId }, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/luggage/quantity/${storeId}`, token);
      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const addFacility = createAsyncThunk(
  "Add luggage facility",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await privatePost("/luggage/facility", token, data);

      return response.payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const luggageSlice = createSlice({
  name: "Luggage create",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    createSuccess: false,
    providerStoreList: [],
    selectedStore: null,
    luggageDetails: null,
    currentStore: null,
    storeGet: false,
    status: false,
    storeCustomer: [],
    storeBagQuantity: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(createLuggage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createLuggage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.createSuccess = true;
    });
    builder.addCase(createLuggage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
      state.createSuccess = false;
    });
    builder.addCase(updateLuggage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLuggage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(updateLuggage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateStore.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(updateStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getLuggageByProvider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLuggageByProvider.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.storeGet = true;
      state.providerStoreList = action.payload;
    });
    builder.addCase(getLuggageByProvider.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.providerStoreList = [];
    });
    builder.addCase(getLuggageDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLuggageDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.luggageDetails = action.payload;
    });
    builder.addCase(getLuggageDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getCustomerByStore.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerByStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.storeCustomer = action.payload;
    });
    builder.addCase(getCustomerByStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.storeCustomer = [];
    });
    builder.addCase(getTolatBagByStore.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTolatBagByStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.storeBagQuantity = action.payload;
    });
    builder.addCase(getTolatBagByStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.storeBagQuantity = 0;
    });
    builder.addCase(addFacility.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addFacility.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(addFacility.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    });
    builder.addCase(updateLuggageStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLuggageStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(updateLuggageStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    });
    builder.addCase(changeBookingStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeBookingStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = true;
      state.error = null;
    });
    builder.addCase(changeBookingStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = false;
    });
  },
  reducers: {
    clearLuggageState: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
      state.createSuccess = false;
      state.providerStoreList = [];
      state.selectedStore = null;
      state.luggageDetails = null;
      state.currentStore = null;
      state.storeGet = false;
      state.status = false;
      state.storeCustomer = [];
      state.storeBagQuantity = 0;
    },
    setClickedStore: (state, { payload }) => {
      state.selectedStore = payload;
    },
    clearSetSelectedStore: (state) => {
      state.selectedStore = null;
    },
    clickedCurrentStore: (state, { payload }) => {
      state.currentStore = payload;
    },
  },
});
export const {
  setClickedStore,
  clearSetSelectedStore,
  clickedCurrentStore,
  clearLuggageState,
} = luggageSlice.actions;
export default luggageSlice.reducer;
